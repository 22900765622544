import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_name.json';
import BlockButton from 'components/buttons/BlockButton';
import FemaleIcon from 'components/SVG/FemaleIcon';
import MaleIcon from 'components/SVG/MaleIcon';
import NonBinaryIcon from 'components/SVG/NonBinaryIcon';
import RingsIcon from 'components/SVG/RingsIcon';
import RingsWithDiamondsIcon from 'components/SVG/RingsWithDiamondsIcon';
import HeartsCoupleIcon from 'components/SVG/HeartsCoupleIcon';
import HeartIcon from 'components/SVG/HeartIcon';
import HeartsEmptyIcon from 'components/SVG/HeartsEmptyIcon';
import HeartsWithQuestionIcon from 'components/SVG/HeartsWithQuestionIcon';
import HeartBrokeIcon from 'components/SVG/HeartBrokeIcon';

export default function RelationshipStatusStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  relationshipStatus,
  setRelationshipStatus,
}) {
  const { t } = useTranslation('common');

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.relationship-status-headline')}
      </span>
      <span className="onboarding-text animated fadeIn">{t('welcome-page.step-gender-text')}</span>

      <div className="icon-select-wrapper animated fadeIn">
        <div
          className={`icon-select ${relationshipStatus === 'married' ? 'is-active' : ''}`}
          onClick={() => {
            setRelationshipStatus('married');
          }}
        >
          <RingsIcon />
          <span>{t('married')}</span>
        </div>

        <div
          className={`icon-select ${relationshipStatus === 'engaged' ? 'is-active' : ''}`}
          onClick={() => {
            setRelationshipStatus('engaged');
          }}
        >
          <RingsWithDiamondsIcon />
          <span>{t('engaged')}</span>
        </div>

        <div
          className={`icon-select ${relationshipStatus === 'relationship' ? 'is-active' : ''}`}
          onClick={() => {
            setRelationshipStatus('relationship');
          }}
        >
          <HeartsCoupleIcon />
          <span>{t('relationship')}</span>
        </div>
      </div>

      <div className="icon-select-wrapper animated fadeIn">
        <div
          className={`icon-select ${relationshipStatus === 'single' ? 'is-active' : ''}`}
          onClick={() => {
            setRelationshipStatus('single');
          }}
        >
          <HeartsEmptyIcon />
          <span>{t('single')}</span>
        </div>

        <div
          className={`icon-select ${relationshipStatus === 'complicated' ? 'is-active' : ''}`}
          onClick={() => {
            setRelationshipStatus('complicated');
          }}
        >
          <HeartsWithQuestionIcon />
          <span>{t('complicated')}</span>
        </div>

        <div
          className={`icon-select ${relationshipStatus === 'broken-heart' ? 'is-active' : ''}`}
          onClick={() => {
            setRelationshipStatus('broken-heart');
          }}
        >
          <HeartBrokeIcon />
          <span>{t('broken-heart')}</span>
        </div>
      </div>

      <BlockButton
        isBottom
        text={`${relationshipStatus ? t('continue') : t('skip')}`}
        onClick={() => {
          scrollToWidget();
          setCurrentStep(currentStep + 1);
        }}
      ></BlockButton>
    </div>
  );
}
