import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  API_URL,
  ASSETS_URL,
  BASE_URL,
  HOROSCOPE_API,
  HOROSCOPE_EXPLANATIONS_API,
  HOROSCOPE_SHORT_API,
  MOON_DATA_API,
  MOON_EXPLANATIONS_API,
} from 'constants/URLS';
import StyledSlider from 'components/StyledSlider';
import { StyledHomePage } from './StyledHomePage';
import { useTranslation } from 'react-i18next';
import { getLanguage } from 'helper/getLanguage';
import PageTitle from 'components/PageTitle';
import * as utc from 'dayjs/plugin/utc'; // import plugin
import * as timezone from 'dayjs/plugin/timezone'; // import plugin
import SectionHeader from 'components/SectionHeader';
import { useAPI } from 'context/APIcontext';
import TaskCard from 'components/TaskCard';
import TagCloud from 'components/TagCloud';
import FriendsCard from 'components/FriendsCard';
import BlockButton from 'components/buttons/BlockButton';
import {
  TASKS_GO_TO_AFFIRMATION,
  TASKS_GO_TO_TAROT,
  TASK_GO_TO_CHALLENGE,
  TASK_GO_TO_HOROSCOPE,
  TASK_GO_TO_MOONDAY,
  TASK_MARK_AS_DONE,
} from 'constants/TASKS';
import AddFriend from 'pages/AddFriend/AddFriend';
import Modal from 'components/Modal';
import SeeYouTomorrow from 'components/SeeYouTomorrow';
import LoadingState from 'components/LoadingState';
import HoroscopeDetail from 'pages/HorosopeDetail/HoroscopeDetail';
import StoryPage from 'pages/StoryPage/StoryPage';
import OnboardingPage from 'pages/OnboardingPage/OnboardingPage';
import BirthchartPage from 'pages/BirthchartPage/BirthchartPage';
import AddFriendsCard from 'components/AddFriendCard';
import MoonCalendar from 'pages/MoonCalendar/MoonCalendar';
import ContentCard from 'components/ContentCard';
import { styled } from 'styled-components';
import FriendDetailPage from 'pages/FriendDetailPage/FriendDetailPage';
import HomePageTitle from './HomePageTitle';
import FriendsPage from '../FriendsPage/FriendsPage';
import AstrologyEvents from 'components/AstrologyEvents';
import AffirmationPage from 'pages/AffirmationPage/AffirmationPage';

require('dayjs/locale/de');

dayjs.extend(utc);
dayjs.extend(timezone);

function getMoonData(selectedDate, language) {
  const now = dayjs();
  let adjustedDate = dayjs(selectedDate);

  if (now.hour() < 9) {
    adjustedDate = adjustedDate.subtract(1, 'days');
  }

  const moonDataUrl = `${MOON_DATA_API}?date=${adjustedDate.format(
    'D-M-YYYY',
  )}&language=${language}`;

  return fetch(moonDataUrl).then((response) => response.json());
}

function getMoonExplanation(moonDay, moonZodiac, zodiacSign, moonPhase, language, retries = 3) {
  const moonExplanationsUrl = `${MOON_EXPLANATIONS_API}?moonDay=${encodeURIComponent(
    moonDay,
  )}&moonZodiac=${encodeURIComponent(
    moonZodiac,
  )}&userZodiac=${zodiacSign}&moonPhase=${moonPhase.replace('_', ' ')}&language=${language}`;

  return fetch(moonExplanationsUrl)
    .then((response) => response.json())
    .then((data) => {
      // Check if the response contains the required fields
      const validResponse = data.every((item) => item.title && item.text && item.do && item.dont);

      if (!validResponse && retries > 0) {
        // If the response is invalid and we have retries left, recall the function
        return getMoonExplanation(moonDay, moonZodiac, zodiacSign, moonPhase, language, retries - 1);
      }

      return data;
    });
}

export default function HomePage({ setCurrentTab }) {
  const { t } = useTranslation('common');
  const {
    userData,
    getTodaysTasks,
    markTaskAsDone,
    setShowChallengeDoneModal,
    userLoggedIn,
    isPremium,
  } = useAPI();
  const [moonData, setMoonData] = useState({});
  const [moonExplanation, setMoonExplanation] = useState({});
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedDateString, setSelectedDateString] = useState(
    `${dayjs().format('D')}. ${t(dayjs().format('MMMM').toLowerCase())} ${dayjs().format('YYYY')}`,
  ); // Get current date
  const [moonChangeTime, setMoonChangeTime] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const [moonDoItems, setMoonDoItems] = useState([]);
  const [selectedMoonDoItem, setSelectedMoonDoItem] = useState();

  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showMoonCalendar, setShowMoonCalendar] = useState(false);
  const [showHoroscopeDetail, setShowHoroscopeDetail] = useState({
    show: false,
    timeframe: 'today',
  });
  const [showBirthChartDetail, setShowBirthChartDetail] = useState(null);
  const [selectedChallenge, setSelectedChallenge] = useState();
  const [moonDayExplanation, setMoonDayExplanation] = useState();
  const [showLandingPage, setShowLandingPage] = useState(false);
  const [showAffirmationPage, setShowAffirmationPage] = useState(false);
  const [ShowSelectedFriend, setShowSelectedFriend] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);

  // Usage of functions
  useEffect(() => {
    setIsLoading(true);
    getMoonData(selectedDate, getLanguage())
      .then((responseMoonData) => {
        setMoonData(responseMoonData);
        setMoonChangeTime(
          responseMoonData.zodiacChangeTimeUTC
            ? dayjs.utc(responseMoonData.zodiacChangeTimeUTC).tz(dayjs.tz.guess()).format('HH:mm')
            : '',
        );
        getMoonExplanation(
          responseMoonData.moonDay,
          responseMoonData.zodiac[0],
          userData.zodiacSign || 'none',
          responseMoonData.phase,
          getLanguage(),
        )
          .then((responseMoonExplanation) => {
            if (responseMoonExplanation.length === 0) {
              //window.location.reload();
            }
            setMoonExplanation(responseMoonExplanation);
            prepareMoonData(responseMoonData, responseMoonExplanation);
            getTodaysTasks(responseMoonData, t).then((tasks) => {
              setTaskList(tasks);
              setIsLoading(false);
            });
          })
          .catch((error) => {
            console.error('Error fetching moon explanation:', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching moon data:', error);
      });
  }, [selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      if (userLoggedIn) {
        const horoscope = await getTransits(userData.userProfile);
        const explanations = await getShortHoroscopeForSelectedDate(
          horoscope.map((entry) => entry.title),
        );

        console.log(explanations);
      }
    };

    fetchData();
  }, [userData]);

  function refreshToday() {
    getTodaysTasks(moonData, t).then((tasks) => {
      setTaskList(tasks);
      setIsLoading(false);
    });
  }

  function prepareMoonData(responseMoonData, responseMoonExplanation) {
    if (responseMoonData.zodiacChangeTimeUTC !== null) {
      setMoonChangeTime(
        dayjs.utc(responseMoonData.zodiacChangeTimeUTC).tz(dayjs.tz.guess()).format('HH:mm'),
      );
    } else {
      setMoonChangeTime(null);
    }

    let splittedDoItems = responseMoonExplanation[0].do
      .map((item) => {
        const matches = item.match(/<b>(.*?)<\/b>([^<]*)/);
        if (matches) {
          return {
            headline: matches[1].replace(':', '').trim(),
            text: matches[2].trim(),
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    if (splittedDoItems && Array.isArray(splittedDoItems)) {
      // Loop through the indices and assign topics if they exist
      for (let i = 0; i < splittedDoItems.length; i++) {
        if (splittedDoItems[i] && splittedDoItems[i].topic === undefined) {
          switch (i) {
            case 0:
              splittedDoItems[i].topic = encodeURIComponent('Body care');
              splittedDoItems[i].icon = '💆‍♀️';
              break;
            case 1:
              splittedDoItems[i].topic = encodeURIComponent('Plants');
              splittedDoItems[i].icon = '🌿';
              break;
            case 2:
              splittedDoItems[i].topic = encodeURIComponent('Health');
              splittedDoItems[i].icon = '🧘‍♀️';
              break;
            case 3:
              splittedDoItems[i].topic = encodeURIComponent('Career');
              splittedDoItems[i].icon = '🤝';
              break;
            case 4:
              splittedDoItems[i].topic = encodeURIComponent('Travel');
              splittedDoItems[i].icon = '✈️';
              break;
            case 5:
              splittedDoItems[i].topic = encodeURIComponent('Spirituality');
              splittedDoItems[i].icon = '🧘';
              break;
            case 6:
              splittedDoItems[i].topic = encodeURIComponent('Big decisions');
              splittedDoItems[i].icon = '💡';
              break;
            case 7:
              splittedDoItems[i].topic = encodeURIComponent('Social activities');
              splittedDoItems[i].icon = '🎭';
              break;
            case 8:
              splittedDoItems[i].topic = encodeURIComponent('Nutrition');
              splittedDoItems[i].icon = '🥗';
              break;
            default:
              break;
          }
        }
      }
    }

    setMoonDoItems(splittedDoItems);
    setSelectedMoonDoItem(splittedDoItems[0]);
  }

  function handleSelectDate(selectedDate) {
    setSelectedDate(selectedDate);
    setSelectedDateString(
      `${dayjs(selectedDate.date).format('D')}. ${t(
        dayjs(selectedDate.date).format('MMMM').toLowerCase(),
      )} ${dayjs(selectedDate.date).format('YYYY')}`,
    );
  }

  function handleTask(task, taskIndex) {
    if (task.action == TASK_MARK_AS_DONE) {
      markTaskAsDone(taskIndex);
      refreshToday();
    }
    if (task.action == TASK_GO_TO_HOROSCOPE) {
      if (userLoggedIn) {
        setSelectedUser(userData.userProfile);
        goToHoroscope();
        if (userData.userProfile) {
          markTaskAsDone(taskIndex);
          refreshToday();
        }
      } else {
        setShowOnboarding(true);
      }
    }
    if (task.action == TASKS_GO_TO_AFFIRMATION) {
      if (userLoggedIn) {
        setShowAffirmationPage(true);
        markTaskAsDone(taskIndex);
        refreshToday();
      } else {
        setShowOnboarding(true);
      }
    }
    if (task.action == TASKS_GO_TO_TAROT) {
      setCurrentTab('tarot');
      markTaskAsDone(taskIndex);
      refreshToday();
    }
    if (task.action == TASK_GO_TO_MOONDAY) {
      setMoonDayExplanation(moonExplanation[0]);
      markTaskAsDone(taskIndex);
      refreshToday();
    }
    if (task.action == TASK_GO_TO_CHALLENGE) {
      if (userLoggedIn) {
        setSelectedChallenge(task);
        refreshToday();
      } else {
        setShowOnboarding(true);
      }
    }
  }

  function goToHoroscope(timeframe = 'today') {
    if (userData.userProfile) {
      setShowHoroscopeDetail({
        show: true,
        timeframe: timeframe,
      });
    } else {
      setShowOnboarding(true);
    }
  }

  function goToMoonDetailPagePage() {
    setMoonDayExplanation(moonExplanation[0]);
  }

  const getTransits = async (userData) => {
    const birthChartAPI = `${HOROSCOPE_API}?language=${getLanguage()}&birthDay=${
      userData.birthDay
    }&birthMonth=${userData.birthMonth}&birthYear=${userData.birthYear}&birthHour=${
      userData.birthHour
    }&birthMinute=${userData.birthMinute}&latitude_degrees=${
      userData.location.latitude_degrees
    }&latitude_direction=${userData.location.latitude_direction}&latitude_minutes=${
      userData.location.latitude_minutes
    }&longitude_degrees=${userData.location.longitude_degrees}&longitude_direction=${
      userData.location.longitude_direction
    }&longitude_minutes=${userData.location.longitude_minutes}&city=${
      userData.location.city
    }&country_short=${userData.location.country_short}&region=${
      userData.location.region
    }&day=${selectedDate.format('D')}&month=${selectedDate.format('M')}&year=${selectedDate.format(
      'YYYY',
    )}`;

    const response = await fetch(birthChartAPI);
    const data = await response.json();
    return data;
  };

  const getShortHoroscopeForSelectedDate = async (titles) => {
    try {
      const language = getLanguage();
      const requests = titles.map(async (title) => {
        const brithChartAPI = `${HOROSCOPE_SHORT_API}?titles[]=${encodeURIComponent(
          title,
        )}&language=${language}`;
        const response = await fetch(brithChartAPI);
        return response.json();
      });
      const responses = await Promise.all(requests);
      const mergedArray = responses.reduce((acc, val) => acc.concat(val), []);
      return mergedArray;
    } catch (error) {
      console.error('Error fetching horoscope explanations:', error);
    }
  };

  return (
    <StyledHomePage>
      <div className="moon-wrapper animated fadeIn">
        <img src={`${ASSETS_URL}/img/moon/${moonData.image}.webp`} alt={`${t(moonData.phase)}`} />
      </div>
      <HomePageTitle
        date={selectedDate}
        onDateChange={(newDate) => {
          handleSelectDate(newDate);
        }}
        topText={
          selectedDate.isSame(dayjs(), 'day') ? t('today') : selectedDate.format('DD.MM.YYYY')
        }
        title={t(moonData.phase)}
        text={
          moonData && moonData.phase
            ? ` ${
                moonChangeTime != null
                  ? `${t('moon-in')} ${t(moonData.zodiac[0])}` //${t('at')} ${moonChangeTime}
                  : `${t('moon-in')} ${t(moonData.zodiac[0])}`
              } `
            : ''
        }
      />

      <div className="element-wrapper animated fadeIn">
        <BlockButton
          noMargin
          isOutlined
          noBorder
          onClick={() => setShowMoonCalendar(true)}
          text={t('to-moon-calendar')}
        ></BlockButton>
      </div>

      {isLoading ? (
        <LoadingState
          title={t('article-page.transit-loading-headline')}
          text={t('article-page.transit-loading-text')}
        />
      ) : (
        <div className="animated fadeIn">
          <SectionHeader
            noSpacing
            title={t('home.todays-tasks')}
            text={t('home.todays-tasks-text')}
          ></SectionHeader>
          <div>
            {selectedDate.format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') && (
              <>
                {taskList.map((task, index) => (
                  <ContentCard
                    key={'task' + index}
                    title={task.title}
                    text={task.intro}
                    topic={task.topic}
                    image={`${API_URL}/api/images/getWatercolorImage.php?title=${task.topic}`}
                    onClick={() => {
                      handleTask(task, index);
                    }}
                    isDone={task.isDone}
                    action={task.action}
                    isTask
                  ></ContentCard>
                ))}
              </>
            )}

            {/*<TaskCard
              title={'nofiy'}
              text={t('test')}
              isDone={false}
              onClick={() => {
                const sendNotification = () => {
                  if (!('Notification' in window)) {
                    throw new Error('Your browser does not support push notification');
                  }
                  Notification.requestPermission().then((Permission) => {
                    const notificationOptions = {
                      body: 'Welcome to Javascript Push Notification',
                      icon: `${BASE_URL}/react/assets/img/tiny-affirmation-logo-share.png`,
                    };
                    new Notification('Push Notification', notificationOptions);
                  });
                };
                sendNotification();
              }}
            ></TaskCard>*/}

            {selectedDate.format('YYYY-MM-DD') !== dayjs().format('YYYY-MM-DD') && (
              <>
                <ContentCard
                  title={t('tasks.todays-horoscope')}
                  text={t('tasks.todays-horoscope-text')}
                  image={`${API_URL}/api/images/getWatercolorImage.php?title=horoscope-${selectedDate.format(
                    'dddd',
                  )}`}
                  onClick={() => {
                    setSelectedUser(userData.userProfile);
                    goToHoroscope();
                  }}
                  isDone={false}
                  isTask
                ></ContentCard>
                <ContentCard
                  title={`${moonData.moonDay}. ${t('tasks.moonday-headline')}, ${t(
                    moonData.zodiac[0],
                  )}`}
                  text={moonExplanation[0].title}
                  image={`${API_URL}/api/images/getWatercolorImage.php?title=zodiac-${moonData.zodiac[0]}`}
                  onClick={() => {
                    goToMoonDetailPagePage();
                  }}
                  isDone={false}
                  isTask
                ></ContentCard>
              </>
            )}
          </div>

          {/*}
          <SectionHeader
            title={t('tools.todays-moon')}
            text={t('based-on-your-birthchart')}
          ></SectionHeader>
          <TagCloud
            items={moonDoItems}
            selectedItem={selectedMoonDoItem}
            onClick={(item) => {
              setSelectedMoonDoItem(item);
            }}
            propertyName="headline"
          ></TagCloud>
          <div className="paper">
            <h4>{selectedMoonDoItem.headline}</h4>
            <p>{selectedMoonDoItem.text}</p>
          </div>*/}

          <SectionHeader
            title={t('home.your-week')}
            text={t('home.your-week-text')}
          ></SectionHeader>

          <div>
            <ContentCard
              image={`${API_URL}/api/images/getWatercolorImage.php?title=weekly-horoscope`}
              title={`${t('weekly-horoscope-headline')}`}
              text={`${t('weekly-horoscope-subheadline')}`}
              onClick={() => {
                if (userLoggedIn && isPremium) {
                  setShowHoroscopeDetail({
                    show: true,
                    timeframe: 'week',
                  });
                } else {
                  setShowOnboarding(true);
                }
              }}
            ></ContentCard>
          </div>

          <SectionHeader
            title={t('home.your-month')}
            text={t('home.your-month-text')}
          ></SectionHeader>

          <div>
            <ContentCard
              image={`${API_URL}/api/images/getWatercolorImage.php?title=monthly-horoscope`}
              title={`${t('monthly-horoscope-headline')}`}
              text={`${t('monthly-horoscope-subheadline')}`}
              onClick={() => {
                if (userLoggedIn && isPremium) {
                  setShowHoroscopeDetail({
                    show: true,
                    timeframe: 'week',
                  });
                } else {
                  setShowOnboarding(true);
                }
              }}
            ></ContentCard>

            <ContentCard
              image={`${API_URL}/api/images/getWatercolorImage.php?title=moon-phase-calendar`}
              title={`${t('moon-phase-calendar-headline')}`}
              text={`${t('moon-phase-calendar-subheadline')}`}
              onClick={() => {
                if (userLoggedIn && isPremium) {
                  setShowMoonCalendar(true);
                } else {
                  setShowOnboarding(true);
                }
              }}
            ></ContentCard>
          </div>

          <SectionHeader
            title={t('home.your-year')}
            text={t('home.your-year-text')}
          ></SectionHeader>

          <div>
            <ContentCard
              image={`${API_URL}/api/images/getWatercolorImage.php?title=solar-return-horoscope`}
              title={`${t('solar-return-headline')}`}
              text={`${t('solar-return-subheadline')}`}
              onClick={() => {
                if (userLoggedIn && isPremium) {
                  setShowBirthChartDetail(userData.userProfile);
                } else {
                  setShowOnboarding(true);
                }
              }}
            ></ContentCard>
          </div>

          <SectionHeader
            title={t('home.your-life')}
            text={t('home.your-life-text')}
          ></SectionHeader>

          <div>
            <ContentCard
              image={`${API_URL}/api/images/getWatercolorImage.php?title=my-birthchart`}
              title={`${t('birthchart-headline')}`}
              text={`${t('birthchart-subheadline')}`}
              onClick={() => {
                if (userLoggedIn && isPremium) {
                  setShowBirthChartDetail(userData.userProfile);
                } else {
                  setShowOnboarding(true);
                }
              }}
            ></ContentCard>

            <ContentCard
              image={`${API_URL}/api/images/getWatercolorImage.php?title=human-design`}
              title={`${t('human-design-headline')}`}
              text={`${t('human-design-subheadline')}`}
              onClick={() => {
                if (userLoggedIn && isPremium) {
                  setShowBirthChartDetail(userData.userProfile);
                } else {
                  setShowOnboarding(true);
                }
              }}
            ></ContentCard>
          </div>

          <AstrologyEvents date={selectedDate} />

          <SeeYouTomorrow />
        </div>
      )}

      {selectedChallenge && (
        <Modal
          onClose={() => {
            setSelectedChallenge(null);
          }}
        >
          <StoryPage
            title={selectedChallenge.content.title}
            text={selectedChallenge.content.text}
            handleClose={() => {
              setSelectedChallenge(null);
            }}
            onChallengeCompleted={() => {
              const challengeIndex = taskList.findIndex(
                (task) => task.action === 'TASK_GO_TO_CHALLENGE',
              );
              markTaskAsDone(challengeIndex);
              setSelectedChallenge(null);
              setShowChallengeDoneModal(true);
            }}
          ></StoryPage>
        </Modal>
      )}

      {moonDayExplanation && (
        <Modal
          onClose={() => {
            setMoonDayExplanation(null);
          }}
        >
          <StoryPage
            title={moonDayExplanation.title}
            text={moonDayExplanation.text}
            handleClose={() => {
              setMoonDayExplanation(null);
            }}
          ></StoryPage>
        </Modal>
      )}

      {showOnboarding && (
        <Modal
          onClose={() => {
            setShowOnboarding(false);
          }}
        >
          <OnboardingPage
            onClose={() => {
              setShowOnboarding(false);
            }}
          ></OnboardingPage>
        </Modal>
      )}

      {showBirthChartDetail && (
        <Modal
          onClose={() => {
            setShowBirthChartDetail(null);
          }}
        >
          <BirthchartPage
            selectedUserData={showBirthChartDetail}
            goBack={() => {
              setShowBirthChartDetail(null);
            }}
          ></BirthchartPage>
        </Modal>
      )}

      {showHoroscopeDetail.show === true && (
        <Modal
          onClose={() => {
            setShowHoroscopeDetail({
              show: false,
              timeframe: 'today',
            });
          }}
        >
          <HoroscopeDetail
            selectedUserData={selectedUser}
            day={selectedDate.format('D')}
            month={selectedDate.format('M')}
            year={selectedDate.format('YYYY')}
            timeframe={showHoroscopeDetail.timeframe}
            goBack={() => {
              setShowHoroscopeDetail({
                show: false,
                timeframe: 'today',
              });
            }}
          ></HoroscopeDetail>
        </Modal>
      )}

      {showAffirmationPage && (
        <Modal
          onClose={() => {
            setShowAffirmationPage(false);
          }}
        >
          <AffirmationPage
            handleClose={() => {
              setShowAffirmationPage(false);
            }}
          ></AffirmationPage>
        </Modal>
      )}

      {showMoonCalendar && (
        <Modal
          onClose={() => {
            setShowMoonCalendar(false);
          }}
        >
          <MoonCalendar></MoonCalendar>
        </Modal>
      )}

      {showLandingPage && (
        <Modal
          onClose={() => {
            setShowLandingPage(false);
          }}
        >
          <OnboardingPage onClose={() => setShowLandingPage(false)}></OnboardingPage>
        </Modal>
      )}
    </StyledHomePage>
  );
}
