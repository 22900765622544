import React, { useState, useEffect } from 'react';
import { BASE_URL } from 'constants/URLS';

import { useTranslation } from 'react-i18next';
import SectionHeader from 'components/SectionHeader';
import { useAPI } from 'context/APIcontext';
import BlockButton from 'components/buttons/BlockButton';
import AddFriend from 'pages/AddFriend/AddFriend';
import Modal from 'components/Modal';
import SeeYouTomorrow from 'components/SeeYouTomorrow';
import ContentCard from 'components/ContentCard';
import PageTitle from 'components/PageTitle';
import LoadingState from 'components/LoadingState';
import * as animationDataNoFriends from 'assets/lottie/animation_onboarding_premium.json';

export default function FriendsPage() {
  const { t } = useTranslation('common');
  const { userData, getFriends } = useAPI();

  const [addFriendOverlay, setAddFriendOverlay] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showSelectedFriend, setShowSelectedFriend] = useState(null);
  const [friends, setFriends] = useState(null);

  useEffect(() => {
    const friends = getFriends();
    setFriends(
      friends.filter((friend) => {
        return !friend.isMe;
      }),
    );
  }, []); // dependency array should be outside the async function

  return (
    <div>
      {friends && friends.length > 0 ? (
        <>
          <PageTitle
            title={t('friends.section-headline')}
            text={t('friends.section-text')}
          ></PageTitle>

          {friends &&
            friends.map((friend, index) => (
              <>
                <div key={'friend' + index}>
                  <ContentCard
                    title={friend.name}
                    text={
                      friend.birthDay
                        ? `${t('birthdate')}: ${friend.birthDay}.${friend.birthMonth}.${
                            friend.birthYear
                          }`
                        : `${t('not-registered')}`
                    }
                    isLocked={false}
                    image={`${BASE_URL}/api/images/getWatercolorImage.php?title=zodiac-${encodeURIComponent(
                      friend.zodiacSign,
                    )}`}
                    onClick={() => {
                      if (userData.userProfile) {
                        setShowSelectedFriend(friend);
                      } else {
                        setShowOnboarding(true);
                      }
                    }}
                    isWhite
                  ></ContentCard>
                </div>
              </>
            ))}

          <BlockButton
            isOutlined
            noBorder
            onClick={() => setAddFriendOverlay(true)}
            text={t('friends.add-friend')}
          ></BlockButton>
        </>
      ) : (
        <>
          <LoadingState
            title={t('friends-page.no-friends-headline')}
            text={t('friends-page.no-friends-text')}
            animationData={animationDataNoFriends}
          ></LoadingState>

          <BlockButton
            isOutlined
            noBorder
            onClick={() => setAddFriendOverlay(true)}
            text={t('friends.add-friend')}
          ></BlockButton>
        </>
      )}

      {addFriendOverlay && (
        <Modal
          onClose={() => {
            setAddFriendOverlay(false);
          }}
        >
          <AddFriend
            onFriendAdded={() => {
              setAddFriendOverlay(false);
              setFriends(getFriends());
            }}
          ></AddFriend>
        </Modal>
      )}

      {showSelectedFriend && (
        <Modal
          onClose={() => {
            setShowSelectedFriend(false);
          }}
        >
          <FriendDetailPage
            onClose={() => {
              setShowSelectedFriend(false);
            }}
          ></FriendDetailPage>
        </Modal>
      )}

      {showOnboarding && (
        <Modal
          onClose={() => {
            setShowOnboarding(false);
          }}
        >
          <OnboardingPage
            onClose={() => {
              setShowOnboarding(false);
            }}
          ></OnboardingPage>
        </Modal>
      )}
    </div>
  );
}
