import React, { useState } from 'react';
import TaskCard from './TaskCard';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import BlockButton from './buttons/BlockButton';
import { BASE_URL } from 'constants/URLS';

const StyledAddFriendCard = styled.div`
  background-color: #fff;
  //margin-left: 3%;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 20px;
  margin-right: 20px;
  box-shadow: #cccccc 0 0 16px;

  .friends-name {
    font-weight: 700;
    font-family: 'clever-german', serif;
    font-size: 26px;
    text-align: center;
    margin: 10px 0 6px;
  }

  .friends-birthday {
    font-family: 'clever-german', serif;
    font-size: 18px;
    text-align: center;
    margin-bottom: 2px;
  }

  .friends-zodiac {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }

  .friends-zodiac-image {
    width: 100px;
    height: 100px;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
`;
function AddFriendsCard({ onClick }) {
  const { t } = useTranslation('common');

  const zodiacBackground = {
    backgroundImage: `url(${BASE_URL}/api/images/getWatercolorImage.php?title=friends})`,
  };

  return (
    <StyledAddFriendCard>
      <div className="friends-zodiac-image" style={zodiacBackground}></div>
      <div className="friends-name">{t('friends.add-friend')}</div>
      <div className="friends-birthday">
        {t('friends.add-friend-text')}
        <BlockButton onClick={() => onClick()} text={t('friends.add-friend')}></BlockButton>
      </div>
    </StyledAddFriendCard>
  );
}

export default AddFriendsCard;
