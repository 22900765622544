import React from 'react';

export default function HeartsWithQuestionIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="square-button-icon"
    >
      <path d="M18.6187 5.12012C9.05425 5.12012 1.28125 12.9537 1.28125 22.5701C1.28125 42.4008 23.5569 52.1855 31.1787 58.5801L32.0012 59.2701L32.8237 58.5801C40.4456 52.1855 62.7212 42.4008 62.7212 22.5701C62.7212 12.9537 54.9482 5.12012 45.3838 5.12012C39.9479 5.12012 35.179 7.72724 32.0012 11.6751C28.8235 7.72724 24.0546 5.12012 18.6187 5.12012ZM18.6187 7.68012C23.7679 7.68012 28.2843 10.3267 30.9338 14.3451L32.0012 15.9651L33.0687 14.3451C35.7182 10.3267 40.2346 7.68012 45.3838 7.68012C53.5549 7.68012 60.1612 14.3279 60.1612 22.5701C60.1612 40.0083 40.8035 49.0281 32.0012 56.0301C23.199 49.0281 3.84125 40.0083 3.84125 22.5701C3.84125 14.3279 10.4476 7.68012 18.6187 7.68012Z" />
      <path d="M31.347 20.4802C31.1886 20.4782 31.0313 20.5084 30.8848 20.5691C27.4638 20.8049 24.6683 23.4449 24.3284 26.8629C24.3113 27.0182 24.325 27.1754 24.3687 27.3253C24.4125 27.4753 24.4853 27.615 24.5831 27.7364C24.6808 27.8578 24.8015 27.9585 24.9382 28.0327C25.075 28.1069 25.2249 28.1531 25.3795 28.1686C25.5341 28.184 25.6901 28.1685 25.8387 28.1229C25.9873 28.0773 26.1254 28.0026 26.245 27.9029C26.3647 27.8033 26.4635 27.6808 26.5358 27.5425C26.608 27.4041 26.6523 27.2528 26.666 27.0972C26.9033 24.7113 28.9012 22.8432 31.347 22.8432C33.9522 22.8432 36.0521 24.9546 36.0521 27.5725C36.0521 29.7966 35.178 30.3787 33.8154 31.4377C32.4528 32.4967 30.5666 33.9427 30.1874 36.8697C30.1619 37.0261 30.168 37.186 30.2053 37.3399C30.2425 37.4939 30.3101 37.6387 30.4042 37.7659C30.4982 37.893 30.6166 37.9998 30.7525 38.0799C30.8884 38.16 31.0389 38.2119 31.1951 38.2323C31.3513 38.2527 31.51 38.2414 31.6618 38.1989C31.8135 38.1563 31.9552 38.0836 32.0784 37.9849C32.2016 37.8862 32.3039 37.7635 32.379 37.6243C32.4542 37.485 32.5007 37.332 32.5158 37.1743C32.7891 35.0647 33.8397 34.4053 35.2515 33.308C36.6634 32.2107 38.4012 30.5865 38.4012 27.5725C38.4012 23.8446 35.4741 20.8444 31.8311 20.5794C31.6785 20.5122 31.5135 20.4784 31.347 20.4802ZM31.3516 41.1595C30.7044 41.1595 30.1782 41.6888 30.1782 42.3398C30.1782 42.9908 30.7044 43.5201 31.3516 43.5201C31.9988 43.5201 32.525 42.9908 32.525 42.3398C32.525 41.6888 31.9988 41.1595 31.3516 41.1595Z" />
    </svg>
  );
}
