import { BASE_URL } from 'constants/URLS';
import React from 'react';

import styled from 'styled-components';
import CheckmarkCircleIcon from './SVG/CheckmarkCircleIcon';
import LockClosedIcon from './SVG/LockClosedIcon';
import ChevronForwardIcon from './SVG/ChevronForwardIcon';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const StyledContentCard = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-bottom: 1px solid whitesmoke;
  border-radius: 0;
  padding-bottom: 20px;
  margin: 20px 3% 20px;
  padding: 0 0 20px 0;
  max-width: 720px;
  cursor: pointer;
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0 10px 10px;

  @media (min-width: 720px) {
    margin: 20px auto 20px;
  }

  &.is-white {
    background-color: #fff;
    box-shadow: #cccccc 0 0 16px;
    padding: 10px;
    border-radius: 10px;

    .content-card-text {
      padding: 0;
    }
  }

  .content-card-image {
    width: 60px;
    min-width: 60px;
    min-height: 60px;
    height: 60px;
    //flex: 1;
    //align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 24px;
    position: relative;
    margin-right: 0;
    font-family: 'clever-german', serif;
    background-color: #fff;
    border: 1px solid whitesmoke;
    border-radius: 10px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      /*background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 80%,
        rgba(255, 255, 255, 1) 100%
      );*/
    }
  }

  .content-card-circle-text {
    width: 60px;
    min-width: 60px;
    min-height: 60px;
    height: 60px;
    //flex: 1;
    //align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 18px;
    position: relative;
    margin-right: 0;
    font-family: 'clever-german', serif;
    background-color: #fff;
    border: 1px solid whitesmoke;
    border-radius: 10px;
    line-height: 1;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      /*background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 80%,
        rgba(255, 255, 255, 1) 100%
      );*/
    }
  }

  .content-card-icon {
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      margin: 0 auto;
      display: inline-block;
      fill: rgba(51, 51, 51, 0.47);
      color: rgba(51, 51, 51, 0.47);
    }
  }

  .content-card-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-left: 20px;
    width: 75%;
    line-height: 1.3;
    width: 100%;

    h4 {
      font-size: 14px;
      margin: 0 0 4px;
      color: #2e3b4b;
      font-weight: 700;
      line-height: 1.2;
      width: 100%;
      font-family: 'Open Sans', sans-serif;

      span {
        white-space: nowrap;
      }
    }

    p {
      margin: 0 0 0 0;
      font-size: 14px;
      color: #2e3b4b;
      color: rgba(51, 51, 51, 0.47);
      font-family: 'Open Sans', sans-serif;
      //color: #adadb3;
      line-height: 1.4;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        white-space: nowrap;
      }
    }

    .task-star {
      font-size: 12px;
      font-weight: 700;

      img {
        width: 10px;
      }
    }
  }

  &.is-rounded-image {
    .content-card-image {
      border-radius: 50%;
    }
  }

  &.is-tarot-reading {
    .content-card-image {
      background-image: none !important;
      background-size: 50% 50%;
      border: 0 none;
      border-radius: 10px;
    }
  }

  &.is-done {
    opacity: 0.1;

    .content-card-icon svg {
      fill: #0b7;
    }
  }
`;

export default function ContentCard({
  title,
  text,
  isLocked = false,
  onClick,
  image,
  isImageReversed,
  isWhite = false,
  isRoundedImage = false,
  circleText = '',
  isTarotReading = false,
  isDone = false,
  isTask = false,
}) {
  return (
    <StyledContentCard
      onClick={onClick}
      className={`content-card ${isLocked ? 'is-locked' : ''} ${isWhite ? 'is-white' : ''}  ${
        isRoundedImage ? 'is-rounded-image' : ''
      } ${image ? '' : 'no-image'} ${isTarotReading ? 'is-tarot-reading' : ''} ${
        isDone ? 'is-done' : ''
      }`}
    >
      {image ? (
        <div
          className="content-card-image"
          style={{
            transform: isImageReversed ? 'rotate(180deg)' : '',
            backgroundImage: `url(${BASE_URL}/api/images/placeholder.jpg)`,
          }}
        >
          <LazyLoadImage
            effect="blur"
            wrapperProps={{
              // If you need to, you can tweak the effect transition using the wrapper style.
              style: { transitionDelay: '1s' },
            }}
            src={image} // use normal <img> attributes as props
          />
        </div>
      ) : (
        <div
          className="content-card-circle-text"
          dangerouslySetInnerHTML={{ __html: circleText }}
        ></div>
      )}

      <div className="content-card-text">
        <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
      {isTask ? (
        <div className="content-card-icon">
          {isDone ? <CheckmarkCircleIcon /> : <ChevronForwardIcon />}
        </div>
      ) : (
        <div className="content-card-icon">
          {isLocked ? <LockClosedIcon /> : <ChevronForwardIcon />}
        </div>
      )}
    </StyledContentCard>
  );
}
