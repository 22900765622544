import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { StyledDesktopNavigation } from './DesktopNavigationStyles';
import HomeIcon from 'components/SVG/HomeIcon';
import HeartIcon from 'components/SVG/HeartIcon';
import BookIcon from 'components/SVG/BookIcon';
import ReaderIcon from 'components/SVG/ReaderIcon';
import LeafIcon from 'components/SVG/LeafIcon';
import TelescropeIcon from 'components/SVG/TelescropeIcon';
import CalendarIcon from 'components/SVG/CalendarIcon';
import PersonIcon from 'components/SVG/PersonIcon';

export default function DesktopNavigation({ currentTab, handleTabChange }) {
  const { t } = useTranslation('common');

  return createPortal(
    <StyledDesktopNavigation>
      <ul className="sidenav">
        <li
          className={`${currentTab === 'home' ? 'is-active' : ''}`}
          onClick={() => {
            handleTabChange('home');
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <TelescropeIcon />
          <span>{t('tab.astrology')}</span>
        </li>

        <li
          className={`${currentTab === 'friends' ? 'is-active' : ''}`}
          onClick={() => {
            handleTabChange('friends');
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <HeartIcon />
          <span>{t('tab.friends')}</span>
        </li>
        <li
          className={`${currentTab === 'tarot' ? 'is-active' : ''}`}
          onClick={() => {
            handleTabChange('tarot');
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <ReaderIcon />
          <span>{t('tab.tarot')}</span>
        </li>
        {/*<li
          className={`${currentTab === 'moon-calendar' ? 'is-active' : ''}`}
          onClick={() => {
            handleTabChange('moon-calendar');
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <CalendarIcon />
          <span>{t('tab.moon-calendar')}</span>
        </li>*/}
        <li
          className={`${currentTab === 'reading' ? 'is-active' : ''}`}
          onClick={() => {
            handleTabChange('reading');
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <BookIcon />
          <span>{t('tab.reading')}</span>
        </li>
        <li
          className={`${currentTab === 'me' ? 'is-active' : ''}`}
          onClick={() => {
            handleTabChange('me');
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 100);
          }}
        >
          <PersonIcon />
          <span>{t('tab.me')}</span>
        </li>
      </ul>
    </StyledDesktopNavigation>,
    document.body,
  );
}
