import React from 'react';

export default function HeartBrokeIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="square-button-icon"
    >
      <path d="M19.1986 8.95996C10.0536 8.95996 2.55859 16.455 2.55859 25.6C2.55859 44.495 23.9286 53.82 31.1986 59.88C31.6686 60.255 32.3286 60.255 32.7986 59.88C40.0686 53.82 61.4386 44.495 61.4386 25.6C61.4386 16.455 53.9436 8.95996 44.7986 8.95996C41.9086 8.95996 39.2686 9.67496 36.9186 10.92C36.5786 11.085 36.3286 11.395 36.2386 11.76L34.5986 18.92C34.5136 19.3 34.6036 19.695 34.8436 19.995C35.0886 20.3 35.4536 20.475 35.8386 20.48H38.6786L34.7586 30.24C34.5986 30.635 34.6486 31.085 34.8886 31.44C35.1286 31.795 35.5286 32.005 35.9586 32H36.9986L31.6786 40.6L33.2386 33.56C33.3236 33.18 33.2336 32.785 32.9936 32.485C32.7486 32.18 32.3836 32.005 31.9986 32H29.5586L32.9586 22.16C33.0836 21.775 33.0236 21.35 32.7836 21.015C32.5486 20.685 32.1686 20.485 31.7586 20.48H27.9186L29.2786 13.28C29.3636 12.81 29.1786 12.33 28.7986 12.04C26.1386 10.08 22.7886 8.95996 19.1986 8.95996ZM19.1986 11.52C21.9836 11.52 24.4986 12.36 26.5986 13.72L25.1186 21.52C25.0486 21.89 25.1436 22.275 25.3836 22.57C25.6236 22.86 25.9786 23.035 26.3586 23.04H29.9586L26.5586 32.88C26.4336 33.265 26.4936 33.69 26.7336 34.025C26.9686 34.355 27.3486 34.555 27.7586 34.56H30.3986L27.5586 47.32C27.4386 47.92 27.7586 48.52 28.3236 48.75C28.8886 48.985 29.5386 48.785 29.8786 48.28L40.3986 31.4C40.6486 31 40.6586 30.495 40.4236 30.085C40.1936 29.675 39.7486 29.425 39.2786 29.44H37.8786L41.7586 19.68C41.9186 19.285 41.8686 18.835 41.6286 18.48C41.3886 18.125 40.9886 17.915 40.5586 17.92H37.4386L38.5986 12.96C40.4736 12.05 42.5036 11.52 44.7986 11.52C52.5486 11.52 58.8786 17.85 58.8786 25.6C58.8786 42.11 40.4386 50.655 31.9986 57.32C23.5586 50.655 5.11859 42.11 5.11859 25.6C5.11859 17.85 11.4486 11.52 19.1986 11.52Z" />
    </svg>
  );
}
