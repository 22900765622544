import styled from 'styled-components';

export const StyledHomePage = styled.div`
  margin: 0 auto;

  .moon-wrapper {
    height: 100px;
    width: 100px;
    margin: 0 auto 10px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .weekday-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 720px;
    margin: 0 3% 0;

    @media (min-width: 720px) {
      margin: 0 auto 0;
    }

    .tab {
      width: 12% !important;
      margin: 0;
    }
  }

  .tab {
    border: 2px solid #eee;
    border-radius: 10px;
    background-color: #fff;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    height: 50px;
    transition:
      background-color 0.5s ease-in-out,
      border-color 0.5s ease-in-out,
      color 0.5s ease-in-out;
    cursor: pointer;
    width: 70px !important;
    margin: 20px 0 20px 6px;

    &:hover {
      background-color: #2e3b4b !important;
      color: #fff !important;
      border: 2px solid #2e3b4b !important;
    }

    &.is-current {
      //background-color: #c19c7b;
      border: 2px solid #c19c7b;
      color: #c19c7b;
    }

    &.is-selected {
      background-color: #c19c7b;
      border: 2px solid #c19c7b;
      color: #fff;
    }

    .weekday {
      font-size: 13px;
      font-weight: 700;
      font-family: 'clever-german', serif;
      line-height: 1;
    }

    .date {
      font-size: 12px;
      font-family: 'Open Sans', sans-serif;
      line-height: 1;
    }
  }
`;
