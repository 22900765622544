import styled from 'styled-components';

export const StyledApp = styled.div`
  line-height: 1.4;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  color: #2e3b4b;
  padding: 10px 0 0;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 10px 0 0;
  }
`;
