import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import ShareOutlineIcon from 'components/SVG/ShareOutlineIcon';
import BookOutlineIcon from 'components/SVG/BookOutlineIcon';
import CloseIcon from 'components/SVG/CloseIcon';
import screenshotShare from 'helper/screenshotShare';
import Modal from 'components/Modal';
import useSwipeDetection from 'hooks/useSwipeDetection';
import * as animationData from 'assets/lottie/swipe-left.json';
import ShareIcon from 'components/SVG/ShareIcon';
import BookIcon from 'components/SVG/BookIcon';
import { BASE_URL, DATA_FOLDER } from 'constants/URLS';
import PlayIcon from 'components/SVG/PlayIcon';
import StopIcon from 'components/SVG/StopIcon';
import CheckmarkIcon from 'components/SVG/CheckmarkIcon';
import ChevronForwardIcon from 'components/SVG/ChevronForwardIcon';
import { useShareImageContext } from 'context/ShareContext';
import { StyledStoryPage } from 'StyledStoryPage';
import BlockButton from 'components/buttons/BlockButton';

export default function StoryPage({
  title,
  text,
  handleClose,
  filePath = null,
  onChallengeCompleted,
}) {
  const { t } = useTranslation('common');
  const { openModal } = useShareImageContext();

  const [storys, setStorys] = useState([]);
  const [currentStory, setCurrentStory] = useState('');
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [currentStoryAnimate, setCurrentStoryAnimate] = useState(true);
  const [showSwipeAnimation, setShowSwipeAnimation] = useState(true);
  const [currentTime, setCurrentTime] = useState();
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    document.title = title + ' - Tiny Affirmations';
    setStorys(JSON.parse(text.replaceAll('```json', '').replaceAll('```', '')));
    setCurrentStory(JSON.parse(text)[currentStoryIndex]);

    if (filePath) {
      setCurrentTime('00:00');
      // Create and configure audio player
      const audio = new Audio(`${DATA_FOLDER}${filePath}`);
      audio.addEventListener('timeupdate', updateTime);
      audio.addEventListener('ended', handleAudioEnded); // Add event listener for end of playback
      setAudioPlayer(audio); // Set audioPlayer state
    }

    const beforeUnloadHandler = () => {
      unloadPage();
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    // Cleanup function
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      unloadPage();
    };
  }, []);

  const unloadPage = () => {
    stopPlayFile();
    clearInterval(intervalId); // Clear interval to prevent memory leaks
    if (audioPlayer) {
      // Check if audioPlayer is not null
      audioPlayer.removeEventListener('timeupdate', updateTime);
      audioPlayer.removeEventListener('ended', stopPlayFile); // Remove event listener for end of playback
    }
  };

  const onSwipeLeft = () => {
    setShowSwipeAnimation(false);
    if (currentStoryIndex < storys.length - 1) {
      setCurrentStoryAnimate(false);
      setCurrentStoryIndex(currentStoryIndex + 1);
      setCurrentStory(storys[currentStoryIndex + 1]);
      setTimeout(() => {
        setCurrentStoryAnimate(true);
      }, 100);
    } else {
      if (!filePath) {
        handleClose();
      } else {
        setCurrentStoryAnimate(false);
        setCurrentStoryIndex(0);
        setCurrentStory(storys[0]);
        setTimeout(() => {
          setCurrentStoryAnimate(true);
        }, 100);
      }
    }
  };

  const onSwipeRight = () => {
    setShowSwipeAnimation(false);
    if (currentStoryIndex > 0) {
      setCurrentStoryAnimate(false);
      setCurrentStoryIndex(currentStoryIndex - 1);
      setCurrentStory(storys[currentStoryIndex - 1]);
      setTimeout(() => {
        setCurrentStoryAnimate(true);
      }, 100);
    }
  };

  const { handleStart, handleEnd } = useSwipeDetection(onSwipeLeft, onSwipeRight);

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  const playFile = () => {
    if (audioPlayer) {
      setIsPlaying(true);
      audioPlayer.play();
      audioPlayer.loop = true;
      const id = setInterval(updateTime, 1000); // Set up interval to update time every second
      setIntervalId(id); // Store interval ID in state
    }
  };

  const stopPlayFile = () => {
    if (audioPlayer) {
      setIsPlaying(false);
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
      setCurrentTime('00:00');
      clearInterval(intervalId); // Clear interval when playback ends
      setIntervalId(null); // Clear interval ID from state
    }
  };

  const handleAudioEnded = () => {
    if (audioPlayer) {
      setCurrentTime('00:00');
      audioPlayer.currentTime = 0; // Reset currentTime to start
      playFile(); // Start playback again
    }
  };

  const updateTime = () => {
    if (audioPlayer) {
      // Check if audioPlayer is not null
      const seconds = Math.floor(audioPlayer.currentTime);
      setCurrentTime(secondsToMMSS(seconds));
    }
  };

  const takeAndShareScreenshot = async () => {
    const imageData = await screenshotShare();
    openModal(imageData);
  };

  const secondsToMMSS = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${padTo2Digits(minutes)}:${padTo2Digits(remainingSeconds)}`;
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  };

  return (
    <StyledStoryPage onTouchStart={handleStart} onTouchEnd={handleEnd}>
      <>
        <div className="story-steps-blocks hide-to-share">
          {storys.map((story, i) => (
            <div key={i} className={`step-block ${currentStoryIndex >= i ? 'is-done' : ''}`}></div>
          ))}
        </div>

        {showSwipeAnimation && (
          <div className="story-swipe-animation hide-to-share">
            <Lottie
              options={defaultOptions}
              height={200}
              width={200}
              isStopped={defaultOptions.isStopped}
              isPaused={defaultOptions.isPaused}
            />
          </div>
        )}

        {currentStoryAnimate && (
          <div className="story-text">
            <div
              className="headline animation animated fadeIn"
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>

            <b
              className="animation animated fadeIn"
              dangerouslySetInnerHTML={{ __html: currentStory.headline }}
            ></b>

            <div
              className="animation animated fadeIn"
              dangerouslySetInnerHTML={{ __html: currentStory.text }}
            ></div>

            {currentStoryAnimate && (
              <div className="story-button-wrapper hide-to-share animation animated fadeIn">
                {/*<audio src={filePath}>
              <p>
                If you are reading this, it is because your browser does not support the audio
                element.{' '}
              </p>
              <embed src={filePath} width="180" height="90" hidden="true" />
            </audio>*/}
                <div
                  className="story-button-share"
                  onClick={() => {
                    takeAndShareScreenshot();
                  }}
                >
                  <ShareIcon />
                  <span>{t('share')}</span>
                </div>

                {filePath && !isPlaying && (
                  <div
                    className="story-button-share"
                    onClick={() => {
                      playFile();
                    }}
                  >
                    <PlayIcon />
                    <span>{t('play')}</span>
                  </div>
                )}

                {filePath && isPlaying && (
                  <div
                    className="story-button-share"
                    onClick={() => {
                      stopPlayFile();
                    }}
                  >
                    <StopIcon />
                    <span>{currentTime}</span>
                  </div>
                )}

                {currentStoryIndex < storys.length - 1 && (
                  <div className="story-button-share" onClick={onSwipeLeft}>
                    <ChevronForwardIcon />
                    <span>{t('continue')}</span>
                  </div>
                )}

                {currentStoryIndex === storys.length - 1 && (
                  <div className="story-button-share" onClick={handleClose}>
                    <CloseIcon />
                    <span>{t('close')}</span>
                  </div>
                )}

                {currentStoryIndex === storys.length - 1 && onChallengeCompleted && (
                  <div
                    className="story-button-share"
                    onClick={() => {
                      onChallengeCompleted();
                    }}
                  >
                    <CheckmarkIcon />
                    <span>{t('task-page.got-it')}</span>
                  </div>
                )}

                {/*<div
              className="story-button-share"
              onClick={() => {
                unloadPage();
                handleClose();
              }}
            >
              <CloseIcon />
              <span>{t('close')}</span>
            </div>*/}
              </div>
            )}
          </div>
        )}

        <div className="screenshot-watermark show-to-share">
          <img
            className="screenshot-watermark-logo"
            src={`${BASE_URL}/react/assets/img/tiny-affirmation-logo-share.png`}
          ></img>
          <div className="screenshot-watermark-text">
            <h4>{t('screenshot-watermark-headline')}</h4>
            <span>{t('screenshot-watermark-text')}</span>
          </div>
        </div>
      </>
    </StyledStoryPage>
  );
}
