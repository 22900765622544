import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PageTitle from 'components/PageTitle';
import { API_URL, ARTICLES_DATA_API, SOUNDS_DATA_API } from 'constants/URLS';
import Modal from 'components/Modal';
import { getLanguage } from 'helper/getLanguage';
import StoryPage from 'pages/StoryPage/StoryPage';
import { useAPI } from 'context/APIcontext';

const StyledMeditationPage = styled.div`
  margin: 0 auto;
`;

export default function MeditationPage() {
  const { t } = useTranslation('common');

  const { isPremium } = useAPI();

  const [selectedView, setSelectedView] = useState('meditation');
  const [categorizedSounds, setCategorizedSounds] = useState(null);
  const [categorizedArticles, setCategorizedArticles] = useState(null);
  const [selectedArticleCategory, setSelectedArticleCategory] = useState(null);
  const [selectedSoundCategory, setSelectedSoundCategory] = useState(null);
  const [isLoadingDone, setIsLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedSound, setSelectedSound] = useState(null);

  useEffect(() => {
    getSounds();
    getArticles();
  }, []);

  const getSounds = async () => {
    const soundsURL = `${SOUNDS_DATA_API}?language=${getLanguage()}`;
    try {
      const response = await fetch(soundsURL);
      const data = await response.json();
      categorizeSounds(data);
    } catch (error) {
      return [];
    }
  };

  const getArticles = async () => {
    const articlesURL = `${ARTICLES_DATA_API}?language=${getLanguage()}`;
    try {
      const response = await fetch(articlesURL);
      const data = await response.json();
      categorizeArticles(data);
    } catch (error) {
      return [];
    }
  };

  function categorizeArticles(articles) {
    const categorized = articles.reduce((acc, article) => {
      const { category, ...rest } = article;
      if (!acc[category]) {
        acc[category] = { category, articles: [] };
      }
      acc[category].articles.push(rest);
      return acc;
    }, {});

    setCategorizedArticles(Object.values(categorized));
    setSelectedArticleCategory(Object.values(categorized)[0]);
  }

  function categorizeSounds(sounds) {
    const categorized = sounds.reduce((acc, article) => {
      const { category, ...rest } = article;
      if (!acc[category]) {
        acc[category] = { category, sounds: [] };
      }
      acc[category].sounds.push(rest);
      return acc;
    }, {});

    setCategorizedSounds(Object.values(categorized));
    setSelectedSoundCategory(Object.values(categorized)[0]);
  }

  return (
    <StyledMeditationPage>
      <PageTitle title={t('wisdom')} text={t('wisdom-text')}></PageTitle>

      <div className="section-select animated fadeIn">
        <div
          className={`section-select-entry ${selectedView === 'meditation' ? 'is-active' : ''}`}
          onClick={() => setSelectedView('spreads')}
        >
          {t('meditation')}
        </div>
        <div
          className={`section-select-entry ${selectedView === 'reading' ? 'is-active' : ''}`}
          onClick={() => setSelectedView('learn')}
        >
          {t('reading')}
        </div>
      </div>

      {selectedView === 'meditation' && (
        <>
          <div className="tag-cloud">
            {categorizedSounds &&
              categorizedSounds.map((category, index) => (
                <div
                  key={category + index}
                  onClick={() => setSelectedSoundCategory(category)}
                  className={`tag ${
                    selectedSoundCategory.category === category.category ? 'is-active' : ''
                  }`}
                >
                  {t(`article-tags.${category.category}`)}
                </div>
              ))}
          </div>

          {selectedSoundCategory &&
            selectedSoundCategory.sounds.map((sound, index) => (
              <div
                key={'sound' + index}
                className="artwork-card"
                onClick={() => setSelectedSound(sound)}
              >
                <div
                  className="artwork-card-image"
                  style={{
                    backgroundImage: `url(${API_URL}/api/images/getWatercolorImage.php?title=${encodeURIComponent(
                      sound.topic,
                    )})`,
                  }}
                ></div>
                <div className="artwork-card-content">
                  {!isPremium && (
                    <div className="artwork-card-icon">
                      <LockClosedIcon></LockClosedIcon>
                      {t(`is-premium-feature`)}
                    </div>
                  )}
                  <div className="artwork-card-headline">{sound.title}</div>
                  <div className="artwork-card-text">{sound.shorttext}</div>
                </div>
              </div>
            ))}

          {selectedSound && (
            <Modal
              onClose={() => {
                setSelectedArticle(null);
              }}
            >
              <StoryPage
                title={selectedArticle.title}
                text={selectedArticle.content}
                filePath={selectedArticle.file_path}
                handleClose={() => {
                  setSelectedArticle(null);
                }}
              ></StoryPage>
            </Modal>
          )}
        </>
      )}

      {selectedView === 'reading' && (
        <>
          <div className="tag-cloud">
            {categorizedArticles &&
              categorizedArticles.map((category, index) => (
                <div
                  key={category + index}
                  onClick={() => setSelectedCategory(category)}
                  className={`tag ${
                    selectedArticleCategory.category === category.category ? 'is-active' : ''
                  }`}
                >
                  {t(`article-tags.${category.category}`)}
                </div>
              ))}
          </div>

          {selectedArticleCategory &&
            selectedArticleCategory.articles.map((article, index) => (
              <div
                key={'article' + index}
                className="artwork-card"
                onClick={() => setSelectedArticle(article)}
              >
                <div
                  className="artwork-card-image"
                  style={{
                    backgroundImage: `url(${API_URL}/api/images/getWatercolorImage.php?title=${encodeURIComponent(
                      article.topic,
                    )})`,
                  }}
                ></div>
                <div className="artwork-card-content">
                  {!isPremium && (
                    <div className="artwork-card-icon">
                      <LockClosedIcon></LockClosedIcon>
                      {t(`is-premium-feature`)}
                    </div>
                  )}
                  <div className="artwork-card-headline">{article.title}</div>
                  <div className="artwork-card-text">{article.shorttext}</div>
                </div>
              </div>
            ))}

          {selectedArticle && (
            <Modal
              onClose={() => {
                setSelectedArticle(null);
              }}
            >
              <StoryPage
                title={selectedArticle.title}
                text={selectedArticle.content}
                handleClose={() => {
                  setSelectedArticle(null);
                }}
              ></StoryPage>
            </Modal>
          )}
        </>
      )}
    </StyledMeditationPage>
  );
}
