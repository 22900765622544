import React, { useState, useEffect, useRef } from 'react';
import { useAPI } from 'context/APIcontext';
import { sendTrackingPixel } from 'helper/tracking';
import { useTranslation } from 'react-i18next';
import WelcomeStep from './steps/WelcomeStep';
import { StyledOnboarding } from './StyledOnboarding';
import BornStep from './steps/BornStep';
import BirthDataStep from './steps/BirthData';
import GenderStep from './steps/GenderStep';
import styled from 'styled-components';
import ArrowBack from 'components/SVG/ArrowBack';
import FinishOnboardingStep from './steps/FinishOnboarding';
import * as dayjs from 'dayjs';
import LandingPage from 'pages/LandingPage/LandingPage';
import EmailStep from './steps/EmailStep';
import RelationshipStatusStep from './steps/RelationshipStatusStep';
import HadBirthChartStep from './steps/HadBirthChartStep';
import HadBirthChartResultStep from './steps/HadBirthChartResultStep';

export default function OnboardingPage({ onClose }) {
  const { t } = useTranslation('common');

  const myRef = useRef(null);

  const { userData, updateUserProfile, userLoggedIn, isPremium } = useAPI();

  const [stepsNumber] = useState(11);
  const [currentStep, setCurrentStep] = useState(1);
  const [gender, setGender] = useState();
  const [location, setLocation] = useState();
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('0');
  const [year, setYear] = useState('');
  const [timeknown, setTimeKnown] = useState(false);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [allowMarketing, setAllowMarketing] = useState(true);
  const [goal, setGoal] = useState();
  const [relationshipStatus, setRelationshipStatus] = useState();

  const [stepPercentage, setStepPercentage] = useState(0);
  const [showEmailAlert, setShowEmailAlert] = useState(false);

  useEffect(() => {
    setStepPercentage(Math.round((currentStep * 100) / stepsNumber));
  }, [currentStep]);

  useEffect(() => {
    if (window.location.pathname === '/') {
      sendTrackingPixel('view-onboarding-page-home');
    } else {
      sendTrackingPixel('view-onboarding-page-article');
    }
  }, []);

  async function handleSetUserData() {
    const zodiacSign = getZodiacSign(Number(day), Number(month));

    const newUserData = {
      isMe: true,
      relationship: '',
      focusTopics: [],
      lastLogin: dayjs().format(),
      birthYear: year,
      birthMonth: month,
      birthDay: day,
      birthHour: hours,
      birthMinute: minutes,
      location: location,
      knowExatTime: timeknown,
      zodiacSign: zodiacSign,
      // Add any other data you collected during onboarding
    };

    await updateUserProfile(newUserData);

    onClose();
  }

  function getZodiacSign(day, month) {
    const zodiacSigns = [
      'capricorn', // 0
      'aquarius', // 1
      'pisces', // 2
      'aries', // 3
      'taurus', // 4
      'gemini', // 5
      'cancer', // 6
      'leo', // 7
      'virgo', // 8
      'libra', // 9
      'scorpio', // 10
      'sagittarius', // 11
    ];

    const lastDayOfSigns = [19, 18, 20, 19, 20, 20, 22, 22, 22, 22, 21, 21]; // Last day the sun is in a sign

    // Adjust month number for array indexing (0-11)
    month -= 1;

    if (day <= lastDayOfSigns[month]) {
      return zodiacSigns[month];
    } else {
      // Move to the next zodiac sign
      return zodiacSigns[(month + 1) % 12];
    }
  }

  function scrollToWidget() {
    myRef.current.scrollIntoView();
  }

  return (
    <>
      {userLoggedIn ? (
        <LandingPage></LandingPage>
      ) : (
        <>
          <div className="container-fullwidth" id="onboarding-widget">
            <StyledOnboarding ref={myRef}>
              {currentStep > 1 && (
                <div
                  className="go-back"
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  <ArrowBack />
                </div>
              )}

              {currentStep === 0 && (
                <HadBirthChartResultStep
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  scrollToWidget={scrollToWidget}
                />
              )}

              {currentStep === 1 && (
                <WelcomeStep
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  scrollToWidget={scrollToWidget}
                />
              )}
              {currentStep === 2 && (
                <GenderStep
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  scrollToWidget={scrollToWidget}
                  gender={gender}
                  setGender={setGender}
                />
              )}
              {currentStep === 3 && (
                <BornStep
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  scrollToWidget={scrollToWidget}
                  location={location}
                  setLocation={setLocation}
                />
              )}
              {currentStep === 4 && (
                <BirthDataStep
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  scrollToWidget={scrollToWidget}
                  location={location}
                  day={day}
                  setDay={setDay}
                  month={month}
                  setMonth={setMonth}
                  year={year}
                  setYear={setYear}
                  hours={hours}
                  setHours={setHours}
                  minutes={minutes}
                  setMinutes={setMinutes}
                  timeknown={timeknown}
                  setTimeKnown={setTimeKnown}
                />
              )}
              {currentStep === 5 && (
                <RelationshipStatusStep
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  scrollToWidget={scrollToWidget}
                  relationshipStatus={relationshipStatus}
                  setRelationshipStatus={setRelationshipStatus}
                />
              )}

              {currentStep === 6 && (
                <EmailStep
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  scrollToWidget={scrollToWidget}
                  location={location}
                  setLocation={setLocation}
                />
              )}
              {currentStep === 7 && (
                <FinishOnboardingStep
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  scrollToWidget={scrollToWidget}
                  handleFinishOnboarding={handleSetUserData}
                />
              )}
            </StyledOnboarding>
          </div>
        </>
      )}
    </>
  );
}
