import React from 'react';

import styled from 'styled-components';

const StyledPageTitle = styled.div`
  //font-family: "Libre Baskerville", serif;
  font-family: 'clever-german', serif;
  //font-family: "breathing", sans-serif;
  padding: 20px 3% 20px;
  line-height: 1 !important;
  font-size: 40px;
  font-weight: 700 !important;
  color: #2e3b4b;
  text-align: center;
  position: relative;
  margin: 0 30px 0;
  //color: #c19c7b;

  ng-lottie {
    margin: 0 auto 20px;
    text-align: center;

    div {
      margin: 0 auto;
      text-align: center;
    }
  }

  .stats.overlay {
  }

  .page-title-big {
    margin-bottom: 6px;
  }

  .page-title-small {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.4;
    color: #2e3b4b;
    min-height: 23px;
  }
`;

export default function PageTitle({ title, text, animated = true }) {
  function createMarkup() {
    return { __html: text };
  }

  return (
    <StyledPageTitle>
      <div className={`page-title-big ${animated ? 'animated fadeIn' : ''}`}>{title}</div>
      {text && (
        <div
          className={`page-title-small ${animated ? 'animated fadeIn' : ''}`}
          dangerouslySetInnerHTML={createMarkup()}
        ></div>
      )}
    </StyledPageTitle>
  );
}
