import { APIContextProvider } from './context/APIcontext';
import { AlertProvider, useAlert } from './context/AlertContext'; // Import useAlert hook
import React from 'react';
import { useTranslation } from 'react-i18next';
import OverviewPage from './pages/OverviewPage/OverviewPage';
import { StyledApp } from './StyledApp';
import { StyledElements } from 'StyledElements';
import { ShareImageContextProvider } from 'context/ShareContext';

function App({ data }) {
  const { t } = useTranslation('common');

  return (
    <StyledApp>
      <AlertProvider>
        <StyledElements>
          {data.module === 'landing-page' && (
            <APIContextProvider>
              <></>
            </APIContextProvider>
          )}
          {data.module === 'checkout-page' && (
            <APIContextProvider>
              <></>
            </APIContextProvider>
          )}
          {data.module === 'onboarding' && <OnboardingPage />}
          {data.module === 'app-widget' && (
            <>
              <APIContextProvider userID={data.userId} userDisplayName={data.userDisplayName} t={t}>
                <ShareImageContextProvider>
                  <OverviewPage startView={data.startView}></OverviewPage>
                </ShareImageContextProvider>
              </APIContextProvider>
            </>
          )}
        </StyledElements>
      </AlertProvider>
    </StyledApp>
  );
}

export default App;
