import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as animationData from 'assets/lottie/swipe-left.json';
import Lottie from 'react-lottie';
import useSwipeDetection from 'hooks/useSwipeDetection';
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon';
import PlayIcon from 'components/SVG/PlayIcon';
import ChevronForwardIcon from 'components/SVG/ChevronForwardIcon';
import CloseIcon from 'components/SVG/CloseIcon';
import StopIcon from 'components/SVG/StopIcon';
import { BASE_URL } from 'constants/URLS';
import { getLanguage } from 'helper/getLanguage';
import TextParser from './TextParser';
import InputRenderer from './InputsHelper';

const StyledCoachingDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 580px;
  margin: 0 auto;
  //padding-bottom: var(--ion-safe-area-bottom, 0);
  //height: calc(100vh - 60px);

  .story-category {
    position: absolute;
    top: 12px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }

  .story-text {
    color: rgb(46, 59, 75);
    font-family: now, sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 1.4;
    margin: 0px 10% 10px;
    width: 90%;
    max-width: 740px;
    z-index: 99;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 375px) {
      font-size: 14px;
    }

    .headline {
      font-family: 'clever-german', serif;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 700 !important;
      color: #2e3b4b;
      color: #c19c7b;
      margin: 0 0 10px;
    }

    b {
      color: #c19c7b;
      font-family: 'breathing', sans-serif;
      font-size: 30px;
      line-height: 1.2;
      position: relative;
      top: 10px;
      left: 4px;
      margin-bottom: 20px;
      display: block;

      @media only screen and (max-width: 375px) {
        font-size: 24px;
      }
    }

    h6 {
      font-size: 12px;
    }

    .tell-me-more {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 10px;
      font-size: 14px;
      display: inline-block;
      padding: 10px 20px;
      color: #7f8aa5;
      border-left: 1px solid #eee;
    }

    .benefits {
      max-width: 420px;

      h6 {
        font-size: 16px;
        font-weight: 700;
        margin: 20px 0 10px;
      }

      ul {
        margin: 0;

        li {
          list-style: none;

          svg {
            width: 20px;
            height: 20px;
            margin-right: 6px;
            fill: #c19c7b;
          }
        }
      }
    }
  }

  .story-steps-blocks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 0 0;
    position: absolute;
    width: 100%;
    top: 0;
    max-width: 740px;

    .step-block {
      width: 100%;
      height: 8px;
      overflow: hidden;
      //box-shadow: #cccccc 0 0 16px;
      background: linear-gradient(to left, #fff 50%, #c19c7b 50%) right;
      background-size: 201%;
      transition: 0.5s ease-out;

      .step-progress {
      }

      &.is-done {
        background-position: left;
        .step-progress {
        }
      }
    }
  }

  .story-swipe-animation {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0.2;
    z-index: 999;
    pointer-events: none;
  }

  .card-lesson {
    line-height: 1.4;
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: 'clever-german', serif;
    margin: 0 10% 6px;
    display: block;

    @media only screen and (max-width: 375px) {
      font-size: 12px;
    }
  }

  .card-keywords {
    b {
      line-height: 1.4;
      font-size: 16px;
      font-weight: 700;
      padding: 0;
      margin: 0 0 12px;
      text-align: center;
      font-family: 'clever-german', serif;
      display: block;

      @media only screen and (max-width: 375px) {
        font-size: 12px;
      }
    }
  }

  .grid {
    padding: 0 3% 20px;
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 3%;
    grid-row-gap: 10px;
    width: 100%;

    b {
      line-height: 1.4;
      font-size: 16px;
      font-weight: 700;
      padding: 0;
      margin: 0 0 0;
      text-align: center;
      font-family: 'clever-german', serif;
      display: block;

      @media only screen and (max-width: 375px) {
        font-size: 12px;
      }
    }

    span {
      font-size: 16px;
      padding-top: 14px;
      font-weight: 400;
      display: block;

      @media only screen and (max-width: 375px) {
        font-size: 10px;
      }
    }
  }
`;

export default function CoachingDetail({ selectedSession, handleClose }) {
  const { t } = useTranslation('common');

  const [storys, setStorys] = useState([]);
  const [currentStory, setCurrentStory] = useState(null);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [currentStoryAnimate, setCurrentStoryAnimate] = useState(true);
  const [showSwipeAnimation, setShowSwipeAnimation] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState('00:00');

  const audioPlayerRef = useRef(null);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    const newStorys = [
      { isIntroPage: true, content: selectedSession, index: 0 },
      ...selectedSession.cards.map((card, index) => ({
        isIntroPage: false,
        content: card,
        index: index + 1,
      })),
    ];

    console.log('newStorys', newStorys);

    setStorys(newStorys);
    setCurrentStory(newStorys[0]);

    const beforeUnloadHandler = () => {
      unloadPage();
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      unloadPage();
    };
  }, [selectedSession]);

  useEffect(() => {
    if (currentStory && currentStory.content.audio_file) {
      stopPlayFile();
      const audioPath = `${BASE_URL}/api/coaching/audio_${getLanguage()}/${
        currentStory.content.audio_file
      }`;
      setCurrentTime('00:00');

      const audio = new Audio(audioPath);
      audio.addEventListener('timeupdate', updateTime);
      audio.addEventListener('ended', handleAudioEnded);
      audioPlayerRef.current = audio;
      playFile();
    }
    return () => {
      stopPlayFile();
    };
  }, [currentStory]);

  const unloadPage = () => {
    stopPlayFile();
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
  };

  const playFile = () => {
    if (audioPlayerRef.current) {
      setIsPlaying(true);
      audioPlayerRef.current.play();
      audioPlayerRef.current.loop = false;
      intervalIdRef.current = setInterval(updateTime, 1000);
    }
  };

  const pausePlayFile = () => {
    if (audioPlayerRef.current) {
      setIsPlaying(false);
      audioPlayerRef.current.pause();
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const stopPlayFile = () => {
    if (audioPlayerRef.current) {
      setIsPlaying(false);
      audioPlayerRef.current.pause();
      audioPlayerRef.current.currentTime = 0;
      setCurrentTime('00:00');
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const handleAudioEnded = () => {
    if (audioPlayerRef.current) {
      setCurrentTime('00:00');
      audioPlayerRef.current.currentTime = 0;
    }
  };

  const updateTime = () => {
    if (audioPlayerRef.current) {
      const seconds = Math.floor(audioPlayerRef.current.currentTime);
      setCurrentTime(secondsToMMSS(seconds));
    }
  };

  const secondsToMMSS = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${padTo2Digits(minutes)}:${padTo2Digits(remainingSeconds)}`;
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  };

  const onSwipeLeft = () => {
    setShowSwipeAnimation(false);
    if (currentStoryIndex < storys.length - 1) {
      setCurrentStoryAnimate(false);
      setCurrentStoryIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        setCurrentStory(storys[newIndex]);
        return newIndex;
      });
      setTimeout(() => {
        setCurrentStoryAnimate(true);
      }, 100);
    } else {
      handleClose();
    }
  };

  const onSwipeRight = () => {
    setShowSwipeAnimation(false);
    if (currentStoryIndex > 0) {
      setCurrentStoryAnimate(false);
      setCurrentStoryIndex((prevIndex) => {
        const newIndex = prevIndex - 1;
        setCurrentStory(storys[newIndex]);
        return newIndex;
      });
      setTimeout(() => {
        setCurrentStoryAnimate(true);
      }, 100);
    }
  };

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  const { handleStart, handleEnd } = useSwipeDetection(onSwipeLeft, onSwipeRight);

  return (
    <StyledCoachingDetail onTouchStart={handleStart} onTouchEnd={handleEnd}>
      <div className="story-steps-blocks hide-to-share">
        {storys.map((story, i) => (
          <div key={i} className={`step-block ${currentStoryIndex >= i ? 'is-done' : ''}`}></div>
        ))}
      </div>

      {showSwipeAnimation && (
        <div className="story-swipe-animation hide-to-share">
          <Lottie
            options={defaultOptions}
            height={200}
            width={200}
            isStopped={defaultOptions.isStopped}
            isPaused={defaultOptions.isPaused}
          />
        </div>
      )}

      {currentStoryAnimate && currentStory && currentStory.isIntroPage && (
        <>
          <div className="story-text">
            <div
              className="headline animation animated fadeIn"
              dangerouslySetInnerHTML={{ __html: currentStory.content.duration }}
            ></div>

            <b
              className="animation animated fadeIn"
              dangerouslySetInnerHTML={{ __html: currentStory.content.title }}
            ></b>

            <div
              className="animation animated fadeIn"
              dangerouslySetInnerHTML={{ __html: currentStory.content.description }}
            ></div>

            <div className="benefits animation animated fadeIn">
              <h6>{t('benefits')}:</h6>
              <ul>
                {currentStory.content.benefits.map((benefit, index) => (
                  <li key={index}>
                    <CheckmarkCircleIcon />
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>

            <div className="story-button-wrapper hide-to-share animation animated fadeIn">
              <div className="story-button-share" onClick={onSwipeLeft}>
                <PlayIcon />
                <span>{t('start-exercise')}</span>
              </div>
            </div>
          </div>
        </>
      )}

      {currentStoryAnimate && currentStory && !currentStory.isIntroPage && (
        <>
          <div className="story-text">
            <div
              className="headline animation animated fadeIn"
              dangerouslySetInnerHTML={{ __html: currentStory.content.metainfo_line_1 }}
            ></div>

            <b
              className="animation animated fadeIn"
              dangerouslySetInnerHTML={{ __html: currentStory.content.title }}
            ></b>

            <div className="animation animated fadeIn">
              <TextParser text={currentStory.content.text} />
            </div>

            <div
              className="animation animated fadeIn"
              style={{ whiteSpace: 'pre-line', width: '100%' }}
            >
              <InputRenderer session={currentStory.content} />
            </div>

            <div className="story-button-wrapper hide-to-share animation animated fadeIn">
              {isPlaying ? (
                <div className="story-button-share" onClick={pausePlayFile}>
                  <StopIcon />
                  <span>{currentTime}</span>
                </div>
              ) : (
                <div className="story-button-share" onClick={playFile}>
                  <PlayIcon />
                  <span>{t('play')}</span>
                </div>
              )}

              {currentStoryIndex < storys.length - 1 ? (
                <div className="story-button-share" onClick={onSwipeLeft}>
                  <ChevronForwardIcon />
                  <span>{t('continue')}</span>
                </div>
              ) : (
                <div className="story-button-share" onClick={handleClose}>
                  <CloseIcon />
                  <span>{t('close')}</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </StyledCoachingDetail>
  );
}
