import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json';
import BlockButton from 'components/buttons/BlockButton';
import { API_URL } from 'constants/URLS';

export default function BornStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  location,
  setLocation,
}) {
  const { t } = useTranslation('common');

  const [locationSearchTerm, setLocationSearchTerm] = useState('');
  const [locationSearchResults, setLocationSearchResults] = useState([]);
  const [locationError, setLocationError] = useState(false);

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  const searchLocation = () => {
    fetch(`${API_URL}/api/searchlocation.php?term=${encodeURI(locationSearchTerm)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setLocationSearchResults(data);
      })
      .catch((error) => {
        console.error('Error searching location:', error);
      });
  };

  const selectLocation = (location) => {
    fetch(`${API_URL}/api/searchlocation.php?place_id=${encodeURI(location.id)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setLocation(data);
        setLocationSearchTerm(location.label);
        setLocationSearchResults([]);
      })
      .catch((error) => {
        console.error('Error selecting location:', error);
      });
  };

  const clearLocationSearchTerm = () => {
    setLocationSearchTerm('');
    setLocationSearchResults([]);
  };

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <span className="onboarding-headline animated fadeIn">{t('welcome-page.step-2-h1')}</span>
      <span className="onboarding-text animated fadeIn">{t('welcome-page.step-2-span')}</span>

      <div className="input-box animated fadeIn" style={{ zIndex: 9999 }}>
        <div className="input-box-headline">{t('welcome-page.step-2-h1')}</div>
        <div className="input-box-input-wrapper">
          <input
            id="birthlocation"
            type="text"
            value={locationSearchTerm}
            onChange={(e) => {
              setLocationSearchTerm(e.target.value);
              if (e.target.value.length > 2) {
                searchLocation(e.target.value);
              }
            }}
            onClick={clearLocationSearchTerm}
            className={`input-box-input ${locationError ? 'is-error' : ''}`}
            placeholder={t('friends.birth-location')}
          />
          {locationSearchResults.length > 0 && (
            <div className="birthlocation-entry">
              {locationSearchResults.map((location, index) => {
                return (
                  <div key={location + index}>
                    <div
                      className="birthlocation-entry-item"
                      onClick={() => selectLocation(location)}
                    >
                      {location.label}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <BlockButton
        isBottom
        disabled={!location}
        text={t('continue')}
        onClick={() => {
          if (location) {
            scrollToWidget();
            setCurrentStep(currentStep + 1);
          } else {
            setLocationError(true);
          }
        }}
      ></BlockButton>
    </div>
  );
}
