import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_gender.json';
import BlockButton from 'components/buttons/BlockButton';
import * as dayjs from 'dayjs';

export default function BirthDataStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  day,
  setDay,
  month,
  setMonth,
  year,
  setYear,
  hours,
  setHours,
  minutes,
  setMinutes,
  timeknown,
  setTimeKnown,
}) {
  const { t } = useTranslation('common');

  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  const clearDay = () => {
    setDay('');
  };

  const clearYear = () => {
    setYear('');
  };

  const clearHours = () => {
    setHours('');
  };

  const clearMinutes = () => {
    setMinutes('');
  };

  const checkDateAndLocation = () => {
    setDateError(false);
    setTimeError(false);

    const dayNum = parseInt(day, 10);
    const monthNum = parseInt(month, 10);
    const yearNum = parseInt(year, 10);
    const dateString = `${yearNum}-${monthNum}-${dayNum}`;
    const date = dayjs(dateString);

    if (
      !date.isValid() ||
      date.date() !== dayNum ||
      date.month() + 1 !== monthNum ||
      date.year() !== yearNum
    ) {
      setDateError(true);
      return;
    }

    if (timeknown) {
      const hoursNum = parseInt(hours, 10);
      const minutesNum = parseInt(minutes, 10);
      const timeValid = hoursNum >= 0 && hoursNum < 24 && minutesNum >= 0 && minutesNum < 60;

      if (!timeValid) {
        setTimeError(true);
        return;
      }
    }

    if (!dateError && !timeError) {
      scrollToWidget();
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <span className="onboarding-headline animated fadeIn">{t('welcome-page.step-1-h1')}</span>
      <span className="onboarding-text animated fadeIn" style={{ marginBottom: '20px' }}>
        {t('welcome-page.step-1-span')}
      </span>

      <div className="input-box animated fadeIn">
        <div className="input-box-input-wrapper">
          <input
            id="number"
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            value={day}
            onChange={(e) => setDay(e.target.value)}
            onClick={clearDay}
            className={`birthday-day ${dateError ? 'is-error' : ''}`}
            placeholder={t('DD')}
          />
          <select
            id="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            className={`birthday-month ${dateError ? 'is-error' : ''}`}
          >
            <option value="0" disabled>
              {t('MM')}
            </option>
            <option value="1">{t('january')}</option>
            <option value="2">{t('february')}</option>
            <option value="3">{t('march')}</option>
            <option value="4">{t('april')}</option>
            <option value="5">{t('may')}</option>
            <option value="6">{t('june')}</option>
            <option value="7">{t('july')}</option>
            <option value="8">{t('august')}</option>
            <option value="9">{t('september')}</option>
            <option value="10">{t('october')}</option>
            <option value="11">{t('november')}</option>
            <option value="12">{t('december')}</option>
          </select>

          <input
            id="number"
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            onClick={clearYear}
            className={`birthday-year ${dateError ? 'is-error' : ''}`}
            placeholder={t('YYYY')}
          />
        </div>

        <div
          className="input-box-input-wrapper is-centered"
          style={{ width: '340px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          <input
            type="checkbox"
            id="birthtime-checkbox"
            checked={timeknown}
            onChange={(e) => setTimeKnown(e.target.checked)}
          />
          <label htmlFor="birthtime-checkbox" className="birthtime-known">
            {t('friends.i-know-the-exact-birth-time')}
          </label>
        </div>

        {timeknown && (
          <div className="input-box-input-wrapper is-centered">
            <div className="birthtime">
              <input
                id="number"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                value={hours}
                onChange={(e) => setHours(e.target.value)}
                onClick={clearHours}
                className={`birthtime-hours ${timeError ? 'is-error' : ''}`}
                placeholder={t('hour')}
              />
              <input
                id="number"
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}
                onClick={clearMinutes}
                className={`birthtime-minutes ${timeError ? 'is-error' : ''}`}
                placeholder={t('minute')}
              />
            </div>
          </div>
        )}
      </div>

      <BlockButton
        isBottom
        disabled={!day || !month || !year}
        text={t('continue')}
        onClick={() => {
          checkDateAndLocation();
        }}
      ></BlockButton>
    </div>
  );
}
