import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json';
import BlockButton from 'components/buttons/BlockButton';

export default function EmailStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  email: propEmail,
  setEmail: setPropEmail,
}) {
  const { t } = useTranslation('common');

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  const clearLocationSearchTerm = () => {
    // Implementation for clearing location search term
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const sendEmailToAPI = async () => {
    const payload = {
      email: email,
      listIds: [9],
      attributes: {
        // Include all required attributes here
      },
    };
    const headers = {
      'Content-Type': 'application/json',
      'api-key': 'your-api-key-here',
    };
    try {
      const response = await fetch('https://api.sendinblue.com/v3/contacts', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <span className="onboarding-headline animated fadeIn">{t('welcome-page.email-step-h1')}</span>
      <span className="onboarding-text animated fadeIn">
        {t('welcome-page.email-step-input-text')}
      </span>

      <div className="input-box animated fadeIn" style={{ zIndex: 9999 }}>
        <div className="input-box-headline">{t('welcome-page.email-step-info')}</div>
        <div className="input-box-input-wrapper">
          <input
            id="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onClick={clearLocationSearchTerm}
            className={`input-box-input ${emailError ? 'is-error' : ''}`}
            placeholder={t('welcome-page.email-step-input-label')}
          />
        </div>
      </div>

      <BlockButton
        isBottom
        disabled={!email || !validateEmail(email)}
        text={t('continue')}
        onClick={() => {
          if (email && validateEmail(email)) {
            scrollToWidget();
            setCurrentStep(currentStep + 1);
            sendEmailToAPI();
          } else {
            setEmailError(true);
          }
        }}
      ></BlockButton>
    </div>
  );
}
