import React from 'react';

export default function RingsWithDiamondsIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="square-button-icon"
    >
      <path d="M27.7211 1.27979L22.2811 5.35979L21.2011 6.19979L22.0411 7.19979L23.6011 9.03978C12.8561 12.5598 5.12109 22.6498 5.12109 34.5598C5.12109 49.3898 17.1711 61.4398 32.0011 61.4398C46.8311 61.4398 58.8811 49.3898 58.8811 34.5598C58.8811 22.6648 51.1261 12.5748 40.4011 9.03978L41.9611 7.19979L42.8011 6.19979L41.7211 5.35979L36.2811 1.27979H27.7211ZM28.6011 3.83979H35.4011L38.8011 6.39979H32.0011V8.95978H37.1611L33.9611 12.7998H30.0411L26.8411 8.95978H29.4411V6.39979H25.2011L28.6011 3.83979ZM25.3611 11.1598L27.1611 13.3598C17.4761 15.5598 10.2411 24.2148 10.2411 34.5598C10.2411 46.5648 19.9961 56.3198 32.0011 56.3198C44.0061 56.3198 53.7611 46.5648 53.7611 34.5598C53.7611 24.2148 46.5261 15.5598 36.8411 13.3598L38.6411 11.1998C48.8361 14.0898 56.3211 23.4198 56.3211 34.5598C56.3211 48.0048 45.4461 58.8798 32.0011 58.8798C18.5561 58.8798 7.68109 48.0048 7.68109 34.5598C7.68109 23.4198 15.1661 14.0498 25.3611 11.1598ZM32.0011 15.3598C42.6211 15.3598 51.2011 23.9398 51.2011 34.5598C51.2011 45.1798 42.6211 53.7598 32.0011 53.7598C21.3811 53.7598 12.8011 45.1798 12.8011 34.5598C12.8011 23.9398 21.3811 15.3598 32.0011 15.3598Z" />
    </svg>
  );
}
