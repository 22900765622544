import React from 'react';

export default function HeartsEmptyIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="square-button-icon"
    >
      <path d="M18.6187 5.12012C9.05425 5.12012 1.28125 12.9537 1.28125 22.5701C1.28125 42.4008 23.5569 52.1855 31.1787 58.5801L32.0012 59.2701L32.8237 58.5801C40.4456 52.1855 62.7212 42.4008 62.7212 22.5701C62.7212 12.9537 54.9482 5.12012 45.3838 5.12012C39.9479 5.12012 35.179 7.72724 32.0012 11.6751C28.8235 7.72724 24.0546 5.12012 18.6187 5.12012ZM18.6187 7.68012C23.7679 7.68012 28.2843 10.3267 30.9338 14.3451L32.0012 15.9651L33.0687 14.3451C35.7182 10.3267 40.2346 7.68012 45.3838 7.68012C53.5549 7.68012 60.1612 14.3279 60.1612 22.5701C60.1612 40.0083 40.8035 49.0281 32.0012 56.0301C23.199 49.0281 3.84125 40.0083 3.84125 22.5701C3.84125 14.3279 10.4476 7.68012 18.6187 7.68012Z" />
    </svg>
  );
}
