import React from 'react';

export default function HeartsCoupleIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="square-button-icon"
    >
      <path d="M13.76 6.3999C9.81 6.3999 6.345 7.7249 3.88 10.1599C1.415 12.5949 0 16.1149 0 20.2399C0 27.1849 4.22 32.3649 9.28 36.9599C14.34 41.5549 20.355 45.7399 24.56 50.7599C24.8 51.0399 25.15 51.1999 25.52 51.1999H25.68C26.05 51.1999 26.4 51.0399 26.64 50.7599C27.855 49.3099 29.23 47.9099 30.68 46.5599C32.5 49.5249 34.31 52.4749 35.48 55.4799C35.63 55.8249 35.92 56.0899 36.28 56.1999L36.4 56.2399C36.75 56.3499 37.125 56.3049 37.44 56.1199C41.75 53.5349 47.215 52.0149 52.12 49.9199C57.025 47.8249 61.505 45.0349 63.28 39.8399C64.335 36.7549 64.205 33.7599 62.96 31.2799C61.715 28.7999 59.41 26.8949 56.44 25.8799C54.305 25.1499 52.095 25.4149 50.2 25.9599C50.845 24.1799 51.2 22.2799 51.2 20.2399C51.2 16.1149 49.785 12.5949 47.32 10.1599C44.855 7.7249 41.39 6.3999 37.44 6.3999C33.715 6.3999 30.515 8.4999 28.24 10.4799C26.61 11.8999 26.145 12.6249 25.6 13.3199C25.055 12.6299 24.55 11.9049 22.92 10.4799C20.65 8.4999 17.485 6.3999 13.76 6.3999ZM13.76 8.9599C16.495 8.9599 19.21 10.6249 21.24 12.3999C23.27 14.1749 24.56 15.9199 24.56 15.9199C24.8 16.2549 25.19 16.4549 25.6 16.4549C26.01 16.4549 26.4 16.2549 26.64 15.9199C26.64 15.9199 27.885 14.1749 29.92 12.3999C31.955 10.6249 34.705 8.9599 37.44 8.9599C40.82 8.9599 43.575 10.0749 45.52 11.9999C47.465 13.9249 48.64 16.6849 48.64 20.2399C48.64 22.8999 47.91 25.2349 46.68 27.4399C46.45 27.5799 46.235 27.6949 46 27.8399C45.735 27.1249 45.645 26.6399 44.88 25.3199C43.685 23.2649 41.845 20.8949 39 19.9199C37.515 19.4099 36.03 19.1549 34.6 19.1999C33.17 19.2449 31.81 19.5599 30.56 20.1599C28.06 21.3549 26.095 23.6749 25.04 26.7599C23.265 31.9549 25.125 36.8949 27.72 41.5599C28.235 42.4899 28.8 43.3999 29.36 44.3199C28.03 45.5449 26.76 46.8199 25.56 48.1599C21.145 43.2349 15.575 39.1899 11 35.0399C6.12 30.6099 2.56 26.1549 2.56 20.2399C2.56 16.6849 3.735 13.9199 5.68 11.9999C7.625 10.0799 10.375 8.9599 13.76 8.9599Z" />
    </svg>
  );
}
