import React, { useEffect, useState } from 'react';
import convertToDecimalCoordinates from 'helper/convertToDecimalCoordinates';
import styled from 'styled-components';
import { API_URL, ARTICLES_DATA_API, BIRTHCHART_API, CHART_EXPLANATION_API } from 'constants/URLS';
import { getLanguage } from 'helper/getLanguage';
import { useTranslation } from 'react-i18next';
import isImportantAspect from 'helper/isImportantAspect';
import generateOverviewData from 'helper/GenerateBirthchartOverviewData';
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json';
import PageTitle from 'components/PageTitle';
import SectionHeader from 'components/SectionHeader';
import ContentCard from 'components/ContentCard';
import LoadingState from 'components/LoadingState';
import Modal from 'components/Modal';
import StoryPage from 'pages/StoryPage/StoryPage';

const StyledBirthchartPage = styled.div`
  width: 100%;
  min-height: 90vh;
  padding: 0;
`;

export default function BirthchartPage({ selectedUserData }) {
  const { t } = useTranslation('common');

  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState('overview');
  const [birthchart, setBirthchart] = useState(null);
  const [housesData, setHousesData] = useState([]);
  const [planetsData, setPlanetsData] = useState([]);
  const [angleData, setAngleData] = useState([]);
  const [asteroidData, setAsteroidData] = useState([]);
  const [pointData, setPointData] = useState([]);
  const [mainAspectsData, setMainAspectsData] = useState([]);
  const [otherAspectsData, setOtherAspectsData] = useState([]);
  const [overviewCorePersonalities, setOverviewCorePersonalities] = useState([]);
  const [overviewStellarComposition, setOverviewStellarComposition] = useState([]);
  const [overviewLuarnodes, setOverviewLuarnodes] = useState([]);

  const [allArticles, setAllArticles] = useState();
  const [selectedArticle, setSelectedArticle] = useState(null);

  const [selectedExplanation, setSelectedExplanation] = useState(null);

  useEffect(() => {
    getBirthchart();
    getArticles();
  }, []);

  const getBirthchart = async () => {
    const cords = convertToDecimalCoordinates(selectedUserData);

    let brithChartAPI = `${BIRTHCHART_API}?language=${getLanguage()}&day=${
      selectedUserData.birthDay
    }&month=${selectedUserData.birthMonth}&year=${selectedUserData.birthYear}&minutes=${
      selectedUserData.birthMinute || '00'
    }&hours=${selectedUserData.birthHour || '00'}&lat=${cords.decimalLatitude}&lang=${
      cords.decimalLongitude
    }`;

    try {
      const response = await fetch(brithChartAPI);
      const data = await response.json();
      console.log({ ...selectedUserData, ...data.birthchart.objects });
      mapBirthChart(data.birthchart);
    } catch (error) {
      return [];
    }
  };

  const getArticles = async () => {
    const articlesURL = `${ARTICLES_DATA_API}?language=${getLanguage()}`;
    try {
      const response = await fetch(articlesURL);
      const data = await response.json();
      const articles = data.filter((article) => article.category === 'astrology');
      setAllArticles(articles);
    } catch (error) {
      return [];
    }
  };

  async function getStory(selectStory) {
    const explanations = await getBirthChartExplanations([selectStory.key], selectedUserData.isMe);
    const explanation = explanations[0];
    setSelectedExplanation(explanation);
  }

  function getBirthChartExplanations(titles, isMe) {
    setIsLoading(true);

    let requests = titles.map((title) => {
      let birthChartAPI = `${CHART_EXPLANATION_API}?titles[]=${encodeURIComponent(
        title,
      )}&chartType=birthchart&isMe=${isMe}&language=${getLanguage()}`;
      return fetch(birthChartAPI).then((response) => response.json());
    });

    return Promise.all(requests).then((responses) => {
      // Use reduce and concat to merge all response arrays into one big array
      setIsLoading(false);
      return responses.reduce((acc, val) => acc.concat(val), []);
    });
  }

  function mapBirthChart(birthchart) {
    const aspectsArray = Object.values(birthchart.aspects).flatMap(Object.values);

    aspectsArray.forEach((aspect) => {
      aspect.active = birthchart.objects[aspect.active];
      aspect.passive = birthchart.objects[aspect.passive];
    });

    // Convert houses to an array if it's not already
    const housesArray = Object.values(birthchart.houses);

    // Convert objects to an array
    let objectsArray = Object.values(birthchart.objects);

    objectsArray.forEach((obj) => {
      if (obj.name === 'Asc') {
        obj.name = 'Ascendant';
      }
      if (obj.name === 'MC') {
        obj.name = 'Medium Coeli';
      }
      if (obj.name === 'IC') {
        obj.name = 'Imum Coeli';
      }
      if (obj.name === 'Desc') {
        obj.name = 'Descendant';
      }
    });

    // Initialize an object to hold the combined data
    const combinedData = {};

    const planetsArray = objectsArray.filter((obj) => obj.type.name === 'Planet');

    const asteroidArray = objectsArray.filter((obj) => obj.type.name === 'Asteroid');
    const pointArray = objectsArray.filter((obj) => obj.type.name === 'Point');

    const anglesArray = objectsArray.filter((obj) => obj.type.name === 'Angle');

    objectsArray.forEach((obj) => {
      // Use the object's house number as the key
      const houseNumber = obj.house.number;

      // If there's no entry for this house number yet, initialize it
      if (!combinedData[houseNumber]) {
        combinedData[houseNumber] = {
          house: housesArray.find((house) => house.number === houseNumber),
          objects: [],
        };
      }

      // Add the object to the list for its house number
      combinedData[houseNumber].objects.push(obj);
    });

    /*const combinedDataArray = Object.keys(combinedData).map((key) => {
      const houseNumber = parseInt(key, 10); // Convert key back to number
      return {
        houseNumber,
        ...combinedData[houseNumber],
      };
    });*/

    const importantAspectsArray = aspectsArray.filter(isImportantAspect);
    const otherAspectsArray = aspectsArray.filter((aspect) => !isImportantAspect(aspect));

    let houseData = [];
    housesArray.forEach((house) => {
      let houseName = `${house.number}_house`;
      let sign = t(house.sign.name.toLowerCase());

      houseData.push({
        title: `${houseName}: ${sign}`,
        key: `${house.name} in ${house.sign.name}`,
        text: t(`${house.name.replace(/ /g, '_').toLowerCase()}_text`),
        topic: encodeURIComponent(house.sign.name),
      });
    });

    let planetsData = [];
    planetsArray.forEach((planet) => {
      let planetName = t(`${planet.name.replace(/ /g, '_').toLowerCase()}`);
      let sign = t(planet.sign.name.toLowerCase());

      planetsData.push({
        title: `${planetName}: ${sign}`,
        key: `${planet.name} in ${planet.sign.name}`,
        text: t(`${planet.name.replace(/ /g, '_').toLowerCase()}_text`),
        topic: encodeURIComponent(planet.name),
      });
    });

    let angleData = [];
    anglesArray.forEach((item) => {
      let itemName = t(`${item.name.replace(/ /g, '_').toLowerCase()}`);
      let sign = t(item.sign.name.toLowerCase());

      angleData.push({
        title: `${itemName}: ${sign}`,
        key: `${item.name} in ${item.sign.name}`,
        text: t(`${item.name.replace(/ /g, '_').toLowerCase()}_text`),
        topic: encodeURIComponent(item.name),
      });
    });

    let asteroidData = [];
    asteroidArray.forEach((item) => {
      let itemName = t(`${item.name.replace(/ /g, '_').toLowerCase()}`);
      let sign = t(item.sign.name.toLowerCase());

      asteroidData.push({
        title: `${itemName}: ${sign}`,
        key: `${item.name} in ${item.sign.name}`,
        text: t(`${item.name.replace(/ /g, '_').toLowerCase()}_text`),
        topic: encodeURIComponent(item.name),
      });
    });

    let pointData = [];
    pointArray.forEach((item) => {
      let itemName = t(`${item.name.replace(/ /g, '_').toLowerCase()}`);
      let sign = t(item.sign.name.toLowerCase());

      pointData.push({
        title: `${itemName}: ${sign}`,
        key: `${item.name} in ${item.sign.name}`,
        text: t(`${item.name.replace(/ /g, '_').toLowerCase()}_text`),
        topic: encodeURIComponent(item.name),
      });
    });

    let mainAspectsData = [];
    importantAspectsArray.forEach((aspect) => {
      const { active, passive, type, movement, orb } = aspect;
      const connection = t(type.toLowerCase());

      const activeObjectName = active.name.replace(/ /g, '_').toLowerCase();
      const passiveObjectName = passive.name.replace(/ /g, '_').toLowerCase();
      const connectionName = connection.replace(/ /g, '_').toLowerCase();

      const title = `${t(activeObjectName)},  ${t(passiveObjectName)} - ${t(connectionName)}`;
      const key = `${active.name} ${connection} ${passive.name}`;
      const text = `${t(activeObjectName + '_text')}`;
      const typeInfo = `${t(movement.formatted.toLowerCase())} - ${t('orbit')}: ${orb}`;

      mainAspectsData.push({
        title,
        key,
        text,
        topic: encodeURIComponent(passive.name),
        typeInfo,
      });
    });

    let otherAspectsData = [];
    otherAspectsArray.forEach((aspect) => {
      const { active, passive, type, movement, orb } = aspect;
      const connection = t(type.toLowerCase());

      const activeObjectName = active.name.replace(/ /g, '_').toLowerCase();
      const passiveObjectName = passive.name.replace(/ /g, '_').toLowerCase();
      const connectionName = connection.replace(/ /g, '_').toLowerCase();

      const title = `${t(activeObjectName)},  ${t(passiveObjectName)} - ${t(connectionName)}`;
      const key = `${active.name} ${connection} ${passive.name}`;
      const text = `${t(activeObjectName + '_text')}`;
      const typeInfo = `${t(movement.formatted.toLowerCase())} - ${t('orbit')}: ${orb}`;

      otherAspectsData.push({
        title,
        key,
        text,
        topic: encodeURIComponent(passive.name),
        typeInfo,
      });
    });

    let sun = planetsArray.find((planet) => planet.name === 'Sun');
    let moon = planetsArray.find((planet) => planet.name === 'Moon');
    let ascendant = objectsArray.find((obj) => obj.name === 'Ascendant');

    let overviewCorePersonalities = [];
    overviewCorePersonalities.push(generateOverviewData(sun, 'sun_text', 'sun', t));

    overviewCorePersonalities.push(generateOverviewData(moon, 'moon_text', 'moon', t));
    overviewCorePersonalities.push(
      generateOverviewData(ascendant, 'ascendant_text', 'ascendant', t),
    );

    // merkur, venus, mars, jupiter, saturn, uranus, neptun, pluto
    let mercury = planetsArray.find((planet) => planet.name === 'Mercury');
    let venus = planetsArray.find((planet) => planet.name === 'Venus');
    let mars = planetsArray.find((planet) => planet.name === 'Mars');
    let jupiter = planetsArray.find((planet) => planet.name === 'Jupiter');
    let saturn = planetsArray.find((planet) => planet.name === 'Saturn');
    let uranus = planetsArray.find((planet) => planet.name === 'Uranus');
    let neptune = planetsArray.find((planet) => planet.name === 'Neptune');
    let pluto = planetsArray.find((planet) => planet.name === 'Pluto');

    let overviewStellarComposition = [];
    overviewStellarComposition.push(generateOverviewData(mercury, 'mercury_text', 'mercury', t));
    overviewStellarComposition.push(generateOverviewData(venus, 'venus_text', 'venus', t));
    overviewStellarComposition.push(generateOverviewData(mars, 'mars_text', 'mars', t));
    overviewStellarComposition.push(generateOverviewData(jupiter, 'jupiter_text', 'jupiter', t));
    overviewStellarComposition.push(generateOverviewData(saturn, 'saturn_text', 'saturn', t));
    overviewStellarComposition.push(generateOverviewData(uranus, 'uranus_text', 'uranus', t));
    overviewStellarComposition.push(generateOverviewData(neptune, 'neptune_text', 'neptune', t));
    overviewStellarComposition.push(generateOverviewData(pluto, 'pluto_text', 'pluto', t));

    // True North Node, True South Node, Vertex, True Lilith, Part of Fortune
    let trueNorthNode = pointArray.find((node) => node.name === 'True North Node');
    let trueSouthNode = pointArray.find((node) => node.name === 'True South Node');
    let vertex = pointArray.find((node) => node.name === 'Vertex');
    let trueLilith = pointArray.find((node) => node.name === 'True Lilith');

    let overviewLuarnodes = [];
    overviewLuarnodes.push(
      generateOverviewData(trueNorthNode, 'true_north_node_text', 'true_north_node', t),
    );
    overviewLuarnodes.push(
      generateOverviewData(trueSouthNode, 'true_south_node_text', 'true_south_node', t),
    );
    overviewLuarnodes.push(generateOverviewData(vertex, 'vertex_text', 'vertex', t));
    overviewLuarnodes.push(generateOverviewData(trueLilith, 'true_lilith_text', 'true_lilith', t));

    setBirthchart(birthchart);
    setHousesData(houseData);
    setPlanetsData(planetsData);
    setAngleData(angleData);
    setAsteroidData(asteroidData);
    setPointData(pointData);
    setMainAspectsData(mainAspectsData);
    setOtherAspectsData(otherAspectsData);
    setOverviewCorePersonalities(overviewCorePersonalities);
    setOverviewStellarComposition(overviewStellarComposition);
    setOverviewLuarnodes(overviewLuarnodes);
    setIsLoading(false);
  }

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <StyledBirthchartPage>
      {isLoading ? (
        <LoadingState
          title={t('article-page.transit-loading-headline')}
          text={t('article-page.transit-loading-text')}
        />
      ) : (
        <>
          <div className="animated fadeIn">
            <Lottie
              options={defaultOptions}
              height={200}
              width={200}
              isStopped={defaultOptions.isStopped}
              isPaused={defaultOptions.isPaused}
            />
          </div>

          <PageTitle
            title={t('birthchart')}
            text={`${selectedUserData.birthDay}.${selectedUserData.birthMonth}.${
              selectedUserData.birthYear
            } - ${t(selectedUserData.zodiacSign)}`}
          ></PageTitle>

          <div className="tag-cloud animated fadeIn">
            <div
              className={`tag ${view === 'knowledge' ? 'is-active' : ''}`}
              onClick={() => setView('knowledge')}
            >
              {t('knowledge-about-birthchart')}
            </div>
            <div
              className={`tag ${view === 'overview' ? 'is-active' : ''}`}
              onClick={() => setView('overview')}
            >
              {t('overview')}
            </div>
            <div
              className={`tag ${view === 'houses' ? 'is-active' : ''}`}
              onClick={() => setView('houses')}
            >
              {t('houses')}
            </div>
            <div
              className={`tag ${view === 'planets' ? 'is-active' : ''}`}
              onClick={() => setView('planets')}
            >
              {t('planets')}
            </div>
            <div
              className={`tag ${view === 'angle' ? 'is-active' : ''}`}
              onClick={() => setView('angle')}
            >
              {t('angle')}
            </div>
            <div
              className={`tag ${view === 'asteroids' ? 'is-active' : ''}`}
              onClick={() => setView('asteroids')}
            >
              {t('asteroids')}
            </div>
            <div
              className={`tag ${view === 'points' ? 'is-active' : ''}`}
              onClick={() => setView('points')}
            >
              {t('points')}
            </div>
            <div
              className={`tag ${view === 'main-aspects' ? 'is-active' : ''}`}
              onClick={() => setView('main-aspects')}
            >
              {t('main-aspects')}
            </div>
            <div
              className={`tag ${view === 'other-aspects' ? 'is-active' : ''}`}
              onClick={() => setView('other-aspects')}
            >
              {t('other-aspects')}
            </div>
          </div>

          {view === 'knowledge' && (
            <>
              <SectionHeader
                title={t('explanations')}
                text={t('birthchart-explanations-text')}
              ></SectionHeader>

              <div className="animated fadeIn">
                {allArticles.map((article, index) => (
                  <div
                    key={'article' + index}
                    className="artwork-card"
                    onClick={() => setSelectedArticle(article)}
                  >
                    <div
                      className="artwork-card-image"
                      style={{
                        backgroundImage: `url(${API_URL}/api/images/getWatercolorImage.php?title=${encodeURIComponent(
                          article.topic,
                        )})`,
                      }}
                    ></div>
                    <div className="artwork-card-content">
                      <div className="artwork-card-headline">{article.title}</div>
                      <div className="artwork-card-text">{article.shorttext}</div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {view === 'overview' && (
            <>
              <SectionHeader
                title={t('birthchart-core-personalities-headline')}
                text={t('birthchart-core-personalities-text')}
              ></SectionHeader>

              <div className="animated fadeIn">
                {overviewCorePersonalities.map((item, index) => (
                  <ContentCard
                    key={'overviewCorePersonalities' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>

              <SectionHeader
                title={t('birthchart-stellar-composition-headline')}
                text={t('birthchart-stellar-composition-text')}
              ></SectionHeader>

              <div className="animated fadeIn">
                {overviewStellarComposition.map((item, index) => (
                  <ContentCard
                    key={'overviewStellarComposition' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>

              <SectionHeader
                title={t('birthchart-lunarnode-headline')}
                text={t('birthchart-lunarnode-text')}
              ></SectionHeader>

              <div className="animated fadeIn">
                {overviewLuarnodes.map((item, index) => (
                  <ContentCard
                    key={'overviewLuarnodes' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>
            </>
          )}

          {view === 'houses' && (
            <>
              <SectionHeader title={t('houses-headline')} text={t('houses-text')}></SectionHeader>

              <div className="animated fadeIn">
                {housesData.map((item, index) => (
                  <ContentCard
                    key={'housesData' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>
            </>
          )}

          {view === 'planets' && (
            <>
              <SectionHeader title={t('planets-headline')} text={t('planets-text')}></SectionHeader>

              <div className="animated fadeIn">
                {planetsData.map((item, index) => (
                  <ContentCard
                    key={'planetsData' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>
            </>
          )}

          {view === 'angle' && (
            <>
              <SectionHeader title={t('angle-headline')} text={t('angle-text')}></SectionHeader>

              <div className="animated fadeIn">
                {angleData.map((item, index) => (
                  <ContentCard
                    key={'angleData' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>
            </>
          )}

          {view === 'asteroids' && (
            <>
              <SectionHeader
                title={t('asteroids-headline')}
                text={t('asteroids-text')}
              ></SectionHeader>

              <div className="animated fadeIn">
                {asteroidData.map((item, index) => (
                  <ContentCard
                    key={'asteroidData' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>
            </>
          )}

          {view === 'points' && (
            <>
              <SectionHeader title={t('points-headline')} text={t('points-text')}></SectionHeader>

              <div className="animated fadeIn">
                {pointData.map((item, index) => (
                  <ContentCard
                    key={'pointData' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>
            </>
          )}

          {view === 'main-aspects' && (
            <>
              <SectionHeader
                title={t('major-aspects-headline')}
                text={t('major-aspects-text')}
              ></SectionHeader>

              <div className="animated fadeIn">
                {mainAspectsData.map((item, index) => (
                  <ContentCard
                    key={'mainAspectsData' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>
            </>
          )}

          {view === 'other-aspects' && (
            <>
              <SectionHeader
                title={t('minor-aspects-headline')}
                text={t('minor-aspects-text')}
              ></SectionHeader>

              <div className="animated fadeIn">
                {otherAspectsData.map((item, index) => (
                  <ContentCard
                    key={'otherAspectsData' + index}
                    title={item.title}
                    text={item.text}
                    topic={'zodiac-' + item.topic}
                    onClick={() => {
                      getStory(item);
                    }}
                  ></ContentCard>
                ))}
              </div>
            </>
          )}
        </>
      )}

      {selectedArticle && (
        <Modal
          onClose={() => {
            setSelectedArticle(null);
          }}
        >
          <StoryPage
            title={selectedArticle.title}
            text={selectedArticle.content}
            handleClose={() => {
              setSelectedArticle(null);
            }}
          ></StoryPage>
        </Modal>
      )}

      {selectedExplanation && (
        <Modal
          onClose={() => {
            setSelectedExplanation(null);
          }}
        >
          <StoryPage
            title={selectedExplanation.title}
            text={selectedExplanation.text}
            handleClose={() => {
              setSelectedExplanation(null);
            }}
          ></StoryPage>
        </Modal>
      )}
    </StyledBirthchartPage>
  );
}
